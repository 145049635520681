@import "../../styles/base/_variables.scss";

.tab-container {
    .tab-list {
        .tab {
            cursor: pointer;
            font-weight: bold;
        }

        /* Variations */
        &.-underline {
            border-bottom: 2px solid var(--orgaimi-color-bg2);
            display: inline-block;
            .tab {
                margin: 0 15px 0 0;
                padding: 0 2px 10px;
                font-size: 1.111rem;
                text-transform: uppercase;

                background: none;
                border: none;
                border-bottom: 2px solid var(--orgaimi-color-bg2);
                color: var(--orgaimi-color-font);
                position: relative;
                bottom: -2px;

                &:last-child {
                    margin-right: 0;
                }

                &.-active {
                    border-color: var(--orgaimi-color-accent1);
                    color: var(--orgaimi-color-font);
                }
            }
        }
        &.-box {
            .tab {
                padding: 20px 25px;
                background-color: var(--orgaimi-color-bg2-a50);
                border: 1px solid var(--orgaimi-color-bg2);
                color: var(--orgaimi-color-secondary-dimmed);
                text-transform: uppercase;

                &.-active {
                    background-color: var(--orgaimi-color-bg);
                    color: var(--orgaimi-color-font);
                }
            }
        }
    }

    .tab-content {
        padding: 20px 0;
        .tab-child {
            display: none;

            &.-active {
                display: block;
            }
        }
    }
}
