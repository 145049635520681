@import "../../styles/base/_variables.scss";

#growth-potential-panel {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    box-shadow: var(--orgaimi-box-shadow);

    .score-bubble {
        color: var(--orgaimi-color-accent1);
    }
}

.growth-potential-panel {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 30px #00000066;
    background-color: var(--orgaimi-color-bg);
    width: pxToEm(728);
    height: 100%;
    max-height: calc(100vh - pxToEm(66));

    header {
        padding: pxToEm(45) pxToEm(39) pxToEm(30);
        z-index: 999;

        .header-title {
            display: flex;
            justify-content: space-between;
        }

        h2 {
            flex-grow: 1;
            margin-bottom: 0;
        }
        h4 {
            margin-bottom: pxToEm(30);
            font-size: pxToEm(25);
            line-height: pxToEm(25);
            text-transform: uppercase;
            text-align: right;

            span {
                font-size: pxToEm(16);
                text-transform: none;
            }
        }
        &.subheader {
            margin-top: pxToEm(-11);
            padding: 0 pxToEm(40) pxToEm(12) pxToEm(40);
        }
    }

    .highlight {
        padding: 38px 35px;
        background-color: var(--orgaimi-color-bg2);
    }

    .info-list {
        background-color: var(--orgaimi-color-bg2-a70);
        align-items: flex-start;
        height: 100%;
        overflow-y: scroll;
        padding: 3px;

        .info-item {
            padding: pxToEm(33) pxToEm(40);

            &:hover {
                background-color: var(--orgaimi-color-bg);
            }

            h3 {
                margin: 0;
                font-size: pxToEm(28);
                line-height: pxToEm(28);
            }

            p {
                margin: 0;
                font-size: pxToEm(22);
                line-height: pxToEm(28);
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--orgaimi-color-divider);
            }
        }
    }

    .button-list {
        margin-bottom: 0;
        display: flex;
        justify-content: space-evenly;
        a {
            display: flex;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            padding: pxToEm(18);
            font-family: "interstate-condensed";
            font-size: pxToEm(20);
            font-weight: 700;
        }
    }
}

@media (max-width: $screen-sm-max) {
    #potential-revenue-panel {
        &.panel-contents {
            position: absolute;
            flex-direction: column;
            height: auto;
        }

        .overview-message,
        .potential-revenue-panel {
            width: 100%;
            .info-list {
                overflow: auto;
            }
        }

        .welcome-panel {
            min-height: auto;
        }
    }
}

@media (max-width: $screen-sm-min) {
    .growth-potential-panel {
        width: 100vw;
    }
}
