@import "../../styles/base/_variables.scss";

.shadow-table {
    height: 60vw;
    max-height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, var(--orgaimi-color-bg2), var(--orgaimi-color-bg), var(--orgaimi-color-bg2), var(--orgaimi-color-bg));
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;
}
