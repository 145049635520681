@import "../../styles/base/_variables.scss";

#home-dashboard {
    margin-top: pxToEm(66);

    .dashboard-cards {
        grid-gap: 5px;
        grid-template-columns: repeat(8, 1fr);

        .card {
            z-index: 1;
            position: relative;
            //padding: 0;
            //border: 0;
            background: none;

            &:hover {
                z-index: 2;
                .btn-edit {
                    display: block;
                }
            }

            .btn-edit {
                position: absolute;
                top: -15px;
                right: -15px;
                z-index: 2;
                display: none;
            }
            &.-no-padding {
                padding: 0;
                .front {
                    .btn-edit {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        z-index: 2;
                    }
                }
            }
            &.-center {
                .flip-container .front {
                    text-align: center;
                    .growth-potential-chart {
                        margin: 0 auto;
                    }
                }
            }
        }

        .bar-comparison-card {
            padding: pxToEm(20);
        }

        .-stat {
            h4 {
                color: var(--orgaimi-color-font);
            }
        }
        .stats {
            min-height: 56px;
            margin-bottom: pxToEm(10);
            display: flex;
            flex-grow: 1;

            .large-number h3 {
                margin-right: pxToEm(10);
                font-size: pxToEm(50);
            }
        }

        .top5 {
            header {
                padding-bottom: 0;
                background-color: var(--orgaimi-color-bg2);
            }

            .top5-items {
                margin-top: 0;
            }
        }

        .large-number {
            text-wrap: nowrap;
        }
    }
}

.flip-container {
    padding: pxToEm(30);
    position: relative;
    transform-style: preserve-3d;
    transition: 0.6s;
    background-color: var(--orgaimi-color-bg2);

    border: 2px solid var(--orgaimi-color-bg2);
    width: 100%;
    height: 100%;

    .front,
    .back {
        backface-visibility: hidden;
        width: 100%;
        height: 100%;
    }

    .front {
        transform: rotateY(0deg);

        .engine-loader {
            height: 100%;
        }
    }

    .back {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        transform: rotateY(180deg);
        margin: 0;
        padding: pxToEm(10) pxToEm(15) pxToEm(30);
        max-width: 100%;
        overflow-y: auto;

        .edit-mode-controls {
            position: sticky;
            top: pxToEm(-10);
            background-color: var(--orgaimi-color-bg2);
            justify-content: space-between;
            border-bottom: 1px solid var(--orgaimi-color-bg);
            padding: pxToEm(8) 0;

            .button {
                margin: 0;
            }
        }
        .character-count {
            margin: pxToEm(12) 0 pxToEm(10);

            &.-error {
                color: var(--orgaimi-color-error-border);
            }
        }
    }
}

.-no-padding .flip-container {
    padding: 0;
}

.-edit {
    .flip-container {
        transform: rotateY(180deg);

        .back {
            visibility: visible;
        }

        .custom-filters {
            .form-field {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .edit-title {
            margin: pxToEm(15) 0;
        }
    }
}

.dashboard-info-card {
    padding: pxToEm(20);
}

.growth-potential-chart {
    width: 234px;
    padding: pxToEm(20) 0;
    text-align: center;
    margin-right: pxToEm(68);
    &:last-child {
        margin-right: 0;
    }

    h3 {
        text-transform: uppercase;
        font-size: pxToEm(50);
        line-height: pxToEm(50);
        span {
            display: block;
            color: var(--orgaimi-color-gray40);
            font-size: pxToEm(16);
            line-height: pxToEm(16);
            font-family: "interstate-condensed";
            font-weight: 400;
        }
    }
    h4 {
        margin-bottom: pxToEm(32);
        color: var(--orgaimi-color-font);
    }

    .semi-circle-pie {
        margin-bottom: 10px;
        padding: 0 10px;
    }

    .chart-summary {
        margin-bottom: pxToEm(40);
        font-family: "interstate-condensed";
        font-weight: 400;
        font-size: pxToEm(16);
        line-height: pxToEm(16);
        text-align: center;
        em {
            color: var(--orgaimi-color-accent1);
            font-style: normal;
        }

        & > div {
            margin-bottom: pxToEm(9);
        }
    }
}
