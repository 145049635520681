@import "../../styles/base/_variables.scss";

.recommendation-panel {
    margin: 20px 0;
    padding: pxToEm(10);
    background-color: var(--orgaimi-color-bg);
    box-shadow: var(--orgaimi-box-shadow);
    display: grid;
    grid-template-columns: pxToEm(313) 1fr;

    font-family: "interstate-condensed";
    position: relative;

    header {
        padding: pxToEm(20) pxToEm(30);
        height: 100%;
        display: flex;
        align-items: center;

        h3 {
            margin: 0;
            color: var(--orgaimi-color-font);
            font-size: pxToEm(34);
            line-height: pxToEm(40);
        }

        .contacts {
            display: flex;
            h4 {
                margin-bottom: pxToEm(10);
                text-align: center;
            }
        }
    }

    .content {
        padding: pxToEm(30);
        background-color: rgba(var(--orgaimi-color-bg2), 0.7);
        flex-grow: 1;
        h2 {
            margin-top: 0;
        }
        h4 {
            margin-bottom: pxToEm(8);
        }
    }

    .recommendation-bullets {
        display: flex;
        & > div {
            width: 50%;
            margin-right: pxToEm(20);

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            color: var(--orgaimi-color-font);
        }
    }

    .team-members {
        flex-direction: column;
        margin-right: pxToEm(30);

        &:last-child {
            margin-right: 0;
        }
    }
}

@media (max-width: $screen-md-min) {
    .recommendation-panel {
        grid-template-columns: 1fr;

        header > div {
            width: 100%;
        }

        .contacts {
            justify-content: center;
        }

        .team-members {
            flex-direction: row;
        }
    }
}
