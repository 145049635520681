@import "../../../../styles/base/variables";

.health-history {
    .chart {
        //max-width: pxToEm(550);
        height: pxToEm(230);
    }

    .content {
        padding: pxToEm(30) 0 0;
        display: flex;
    }
    .intro {
        margin: 0 pxToEm(40) 0 0;
        font-size: pxToEm(22);
        line-height: pxToEm(30);
    }
}
