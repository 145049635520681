@import "../../../../styles/base/variables";

.theme-score {
    margin-bottom: pxToEm(30);
    padding: pxToEm(30);
    position: relative;

    background-color: var(--orgaimi-color-bg2);

    .theme-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        width: 80px;
        filter: invert(16%) sepia(15%) saturate(1809%) hue-rotate(154deg) brightness(91%) contrast(88%); //Gray
    }

    .theme-header {
        margin-bottom: 20px;
        padding: 20px 30px 10px 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--orgaimi-color-bg);
        z-index: 1;
        position: relative;

        h2 {
            color: var(--orgaimi-color-secondary-dimmed);
        }
        .theme-preview {
            width: 200px;
            max-width: 50%;
        }
    }

    .button.-text {
        margin: 0;
        padding: 0.833rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        opacity: 1;
        transition: opacity 400ms;

        &:hover {
            opacity: 0.8;
        }

        .svg-inline--fa {
            transition: transform 800ms ease-in-out;
            transform: rotate(0deg);
        }

        &.-active {
            .svg-inline--fa {
                transform: rotate(180deg);
            }
        }
    }

    .theme-content {
        padding: 20px 30px 10px 30px;
        &.hidden-content:not(.-active) {
            display: none;
        }
    }

    .theme-list {
        margin-top: 40px;
        display: grid;
        grid-template-columns: 25% 1fr;
        grid-gap: pxToEm(10);
        align-items: center;
    }

    .theme-item {
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: pxToEm(40);
        align-items: center;
    }

    .theme-name {
        margin: 0;
    }

    .feature-name {
        margin-bottom: pxToEm(20);
        min-width: 40%;
    }
    .feature-content {
        margin-bottom: pxToEm(20);
        width: 100%;
    }

    .theme-content {
        .title-bar {
            margin-bottom: pxToEm(40);
            display: flex;
            justify-content: space-between;
            grid-column-start: 2;
            font-size: pxToEm(16);
        }

        .tile-bar {
            margin: 0;

            &.-primary {
                margin-bottom: pxToEm(20);
            }
        }
        .theme-name {
            margin-bottom: pxToEm(20);
        }
    }
}

.theme-summary {
    display: grid;
    grid-template-columns: 25% 1fr 25%;
    align-items: center;
    justify-items: center;

    .chart-theme-performance {
        width: 100%;
        height: 400px;
        margin-bottom: 20px;
        justify-content: center;
        position: relative;

        .external-legend {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 20px;

            .external-label {
                margin: 0 20px 0 0;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: $screen-md-min) {
    .theme-summary {
        grid-template-columns: 1fr;
    }
}
