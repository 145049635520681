@import "../../styles/base/_variables.scss";

.filter-menu {
    position: fixed;
    top: pxToEm(66);
    right: 0px;
    height: calc(100vh - pxToEm(66));
    z-index: 998;
    background-color: var(--orgaimi-color-bg);
    box-shadow: var(--orgaimi-box-shadow);

    transform: translateX(100%);
    transition: transform 400ms ease-in-out;

    .input-group {
        flex-direction: column;
        padding: pxToEm(30);
        align-items: unset;
    }

    h2 {
        text-align: left;
    }

    // Variation
    &.-active {
        transform: translateX(0%);
    }

    .button-group {
        p {
            margin: pxToEm(10) 0;
        }
    }
}

.saved-filters {
    .button {
        margin: 0;
    }
    .save-error {
        margin-top: 10px;
    }

    .action-items {
        padding-top: 10px;
        display: block;
        text-align: left;

        & > .button {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
