@import "../../styles/base/_variables.scss";

.flags {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;

    .flag {
        margin-right: 1px;
        width: 8px;
        height: 100%;

        &.-healthy {
            background-color: var(--orgaimi-color-healthy);
        }
        &.-independence {
            background-color: var(--orgaimi-color-warning);
        }
    }
}
