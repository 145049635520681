@import "../../styles/base/_variables.scss";
@import "../../styles/base/_mixins.scss";

#global-dashboard {
    //margin-bottom: pxToEm(50);
    box-shadow: var(--orgaimi-box-shadow);

    position: sticky;
    left: 0;
    z-index: 3;
    background-color: var(--orgaimi-color-bg-dimmed);

    .filter-stats {
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .filter-item {
            text-align: center;
            display: block;
            img {
                display: block;
                text-align: center;
                margin: 0 auto 5px;
            }
            b:after {
                content: " ";
            }

            &.-columns .values {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            .value-item {
                margin-bottom: pxToEm(6);
            }
        }
    }

    .filter-summary-wrapper {
        position: absolute;
        top: 86px;
        left: 0;
        width: 100%;
        background: var(--orgaimi-color-bg2);
        z-index: 3;

        max-height: 0;
        overflow: hidden;
        transition: max-height 600ms ease-in-out;

        .saved-filter {
            padding: pxToEm(20);
        }

        &.-active {
            max-height: 720px;
            overflow-y: auto;
        }

        .filter-summary {
            width: 100%;
            justify-content: space-around;
            .filter-item {
                max-width: fit-content;
                min-width: 10%;
            }
        }
    }
}

@media (max-width: 950px) {
    #global-dashboard {
        .filter-box {
            flex-direction: column;
            align-items: flex-start;
            .filter-item {
                img {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-min) {
    #global-dashboard {
        .filter-stats {
            justify-content: center;
        }
        .viewing-for,
        .filter-box {
            display: none;
        }
    }
}
