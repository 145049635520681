.icon-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h4 {
        width: 100%;
    }

    .icon-item {
        min-width: 100px;
        padding: 0 10px;

        h6 {
            margin-bottom: pxToEm(10);
            text-align: center;
            color: var(--orgaimi-color-gray70);

            a {
                text-decoration: none;

                transition: color 400ms ease-in-out;

                &:hover {
                    color: var(--orgaimi-color-healthy);
                }
            }
        }

        &.-inactive {
            opacity: 0.3;
        }

        &.-hoverable:hover {
            .profile-icon {
                width: 100%;
                border-radius: 5px;
                span,
                image {
                    opacity: 0;
                }
            }
            .icon-menu {
                opacity: 1;
                //background: var(--orgaimi-color-bg2);
            }
        }
    }

    .profile-icon {
        margin: 0 auto 8px;
    }
}
