@import "../../../../styles/base/_variables.scss";

.table-filter {
    position: relative;
    .form-field {
        border: 1px solid var(--orgaimi-color-bg);
    }

    .filter-tooltip {
        display: none;
        position: absolute;
        bottom: -24px;
        left: 50%;
        z-index: 3;
        transform: translateX(-50%) translateY(100%);
        background-color: var(--orgaimi-color-bg);

        &.-active {
            display: block;
        }
        &.-right {
            left: auto;
            right: pxToEm(-20);
            transform: translateX(0%) translateY(100%);
        }
    }
}
