@import "../../../../styles/base/variables";

.notify-admin {
    display: flex;

    .messages {
        flex-grow: 1;
    }

    .templates {
        min-width: 25vw;
    }

    .form-field {
        margin-bottom: 0.5em;
    }

    textarea.email-body {
        height: 300px;
    }

    .template-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: $screen-md-min) {
}
