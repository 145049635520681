.login-panel {
    margin: 30px auto;
    padding: 20px 0;
    max-width: 400px;
    min-height: 300px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    background-color: var(--orgaimi-color-bg2);
}
