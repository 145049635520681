@import "../base/variables";

/*.modal-overlay {
    background-color: var(--orgaimi-color-bg2-a80);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow-y: auto;
    padding: 40px 20px 30px;
    display: flex;
    align-items: flex-start;
}*/

.modal::backdrop {
    background: rgba(3, 32, 45, 0.8); // Variable doesn't work here
}

.modal {
    background-color: var(--orgaimi-color-bg2);
    padding: pxToEm(80);
    border: 0;
    position: fixed;
    color: inherit;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-height: 100vh;
    margin: 0 auto;
    box-shadow: var(--orgaimi-box-shadow);
    z-index: 900;
    img {
        max-width: 100%;
        height: auto;
        max-height: 85vh;
    }

    .modal-close {
        cursor: pointer;
        position: absolute;
        top: pxToEm(20);
        right: pxToEm(20);
        z-index: 2;
        padding: 3px;

        .svg-inline--fa {
            font-size: pxToEm(20);
            line-height: pxToEm(20);
            color: var(--orgaimi-color-secondary-dimmed);
        }
    }

    &.-center {
        top: 50%;
        transform: translateY(-50%);
    }
    &.-full {
        width: 90%;
        height: 90%;
        max-width: 100%;
    }

    .loader-holder {
        width: 250px;
        height: 250px;
    }

    &.-notification {
        background-color: var(--orgaimi-color-info-bg);
    }

    &.-panel {
        width: 90%;
    }

    &.-dialog {
        border-top: 8px solid var(--orgaimi-color-error);
        position: relative;
        background-color: var(--orgaimi-color-bg2);
        padding: pxToEm(80);
        color: inherit;
        overflow: visible;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        max-height: 100vh;
        margin: 0 auto;
        box-shadow: var(--orgaimi-box-shadow);
        z-index: 900;
        img {
            max-width: 100%;
            height: auto;
            max-height: 85vh;
        }

        .display-icon {
            position: absolute;
            top: -24px;
            left: 50%;
            z-index: 2;
            transform: translateX(-50%);
            background-color: var(--orgaimi-color-error);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;

            .svg-inline--fa {
                color: var(--orgaimi-color-font);
            }
        }

        &.-info {
            border-color: var(--orgaimi-color-info);
            .display-icon {
                background-color: var(--orgaimi-color-info);
            }
        }

        &.-warning {
            border-color: var(--orgaimi-color-warning);
            .display-icon {
                background-color: var(--orgaimi-color-warning);
            }
        }

        &.-success {
            border-color: var(--orgaimi-color-secondary);
            .display-icon {
                background-color: var(--orgaimi-color-secondary);
            }
        }

        &.-confirm {
            border-color: var(--orgaimi-color-primary);
            .display-icon {
                background-color: var(--orgaimi-color-primary);
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .modal {
        max-width: 1000px;
    }
}
