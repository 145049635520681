@import "../../styles/base/variables";

#offices {
    height: calc(100vh - pxToEm(66) - 90px);
    position: relative;

    .color-legend {
        display: none;
    }
}

#map {
    width: 100%;
    height: 100%;
}

.map-legend {
    position: absolute;
    bottom: 0;
    z-index: 3;
    font-size: 0.833rem;
    color: var(--orgaimi-color-font);

    ul {
        list-style: none;
        display: flex;

        li {
            margin-right: 15px;
        }
    }

    .dot {
        margin-right: 7px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--orgaimi-color-gray80);
        display: inline-block;

        &.-healthy {
            background-color: var(--orgaimi-color-healthy);
        }

        &.-unhealthy {
            background-color: var(--orgaimi-color-unhealthy);
        }
    }
}

.map-tooltip {
    border: 2px solid var(--orgaimi-color-primary);
    color: var(--orgaimi-color-font);
    background: var(--orgaimi-color-bg-a90);
    margin: 0 auto;
    padding: 0 10px;
    display: grid;
    justify-items: center;
    font-size: 0.889rem;

    font-family: "interstate-condensed";

    h1,
    h5 {
        margin-bottom: 5px;
        font-family: inherit;
    }
    h1 {
        color: var(--orgaimi-color-primary);
    }
    h5,
    a {
        color: var(--orgaimi-color-font);
    }

    .score-bubble {
        margin: 4px 0 8px;
        background-color: var(--orgaimi-color-bg2-a70);
    }

    /* Variations */
    &.-healthy {
        border: 2px solid var(--orgaimi-color-secondary);

        h1 {
            color: var(--orgaimi-color-secondary);
        }
    }

    .team-members {
        margin-bottom: 0;
        .team-member {
            margin-bottom: 0;

            h6 {
                margin-bottom: 4px;
            }
        }
    }
}

.no-map {
    left: 0;
    line-height: 200px;
    margin-top: -100px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    z-index: 100;
}
