@import "../../../../styles/base/variables";

.team-dashboard {
    min-height: pxToEm(154);
    background-color: var(--orgaimi-color-header-bg-a50);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;

    & > div:not(.divide) {
        padding: pxToEm(10) pxToEm(25);
        display: flex;
        align-items: center;
        justify-content: stretch;

        h4 {
            flex-grow: 0;
        }

        &:last-child {
            padding-right: 0;
        }
        &.client-logo {
            flex-grow: 1;
            padding-left: 0;
        }
        &.flags {
            padding: 0;
        }
    }
    .divide {
        width: 1px;
        background-color: var(--orgaimi-color-bg);
    }

    h2 {
        color: var(--orgaimi-color-secondary-dimmed);
        span {
            color: var(--orgaimi-color-gray50);
            font-size: pxToEm(16);
            line-height: 1;
            display: block;
            margin-top: pxToEm(10);

            text-transform: uppercase;
        }
    }

    div.stat {
        display: flex;
        height: 100%;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        .stats {
            min-height: 56px;
            margin-bottom: pxToEm(10);
            display: flex;
            flex-grow: 1;

            .large-number h3 {
                margin-right: pxToEm(10);
                font-size: pxToEm(40);
            }
        }
    }

    .stat-group {
        & > div {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: stretch;
            flex-direction: column;

            & > div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
            }

            h4 {
                flex-grow: 0;
            }
        }
    }

    .score-bubble-wrapper,
    .semi-circle-pie,
    .percent-revenue > div {
        flex-grow: 1;
    }

    .score-bubble,
    .probability-bubble {
        margin-bottom: pxToEm(8);
    }

    .semi-circle-pie {
        h2 {
            font-size: pxToEm(34);
            line-height: pxToEm(34);
            margin-bottom: 0;
        }
    }
}

.team-dashboard {
    padding: 0 pxToEm(30);
    h2 {
        margin: 0 0 0 pxToEm(20);
    }
}

@media (min-width: $screen-md-min) {
    .employee-top5 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
    }
}

@media (max-width: $screen-sm-max) {
    .team-dashboard {
        flex-wrap: wrap;
        & > div:not(.divide) {
            &.client-logo {
                width: 100%;
            }
        }
    }
}
