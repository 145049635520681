@import "../../styles/base/variables";

.corner-ribbon {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: pxToEm(32) pxToEm(32) 0 0;
    border-color: var(--orgaimi-color-bg2) transparent transparent transparent;
    color: var(--orgaimi-color-accent1);
    font-size: pxToEm(16);

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    span {
        position: absolute;
        top: pxToEm(-32);
        left: pxToEm(5);
    }
}
