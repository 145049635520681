@import "../../../styles/base/variables";

#office-panel {
    margin: 28px 0 0 0;
    top: 50%;
    right: 0;
    left: auto;
    height: auto;
    transform: translate3d(100%, 0, 0) translateY(-50%);

    box-shadow: var(--orgaimi-box-shadow);

    &.-active {
        transform: translate3d(0, 0, 0) translateY(-50%);
    }

    .btn-close {
        font-size: pxToEm(40);
        margin: 0;
        padding: 0.75em;
        position: absolute;
        top: 0;
        right: 15px;
        .svg-inline--fa {
            font-size: 1.2rem;
        }
    }
}

.office-panel {
    background-color: var(--orgaimi-color-bg-dimmed);
    color: var(--orgaimi-color-font);
    overflow-y: scroll;
    max-height: calc(100vh - pxToEm(66));

    h1,
    h3,
    h4 {
        color: var(--orgaimi-color-font);
    }

    h1 {
        margin: 0;
        font-size: pxToEm(62);
        line-height: pxToEm(62);
    }

    h3 {
        margin-bottom: pxToEm(12);
        font-size: pxToEm(36);
        line-height: pxToEm(36);
        text-transform: uppercase;
    }

    h4 {
        text-transform: uppercase;
    }

    .office-info {
        font-size: pxToEm(16);
        line-height: pxToEm(20);
        color: var(--orgaimi-color-gray70);
    }

    .tab-container {
        text-align: left;
        .tab-list {
            margin: pxToEm(30);
            button {
                padding: 0.75em;
            }
        }
        .tab-content {
            display: block;
            text-align: center;
        }
        .panel-content {
            min-height: 70vh;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            justify-items: center;
            width: 100%;
            height: 100%;
            padding: 1em;
            overflow-y: auto;

            .shadow-table {
                width: 100%;
                height: 100%;
            }
            .chart-item {
                display: grid;
                align-items: center;
                justify-content: center;
                justify-items: center;
            }
        }

        .insight-panel {
            display: grid;
            width: 100%;
            justify-content: center;
            justify-items: center;
            align-items: center;
            align-content: start;
            .chart-item {
                color: var(--orgaimi-color-font);
                display: block;
                width: 500px;
                height: 350px;
            }
        }

        .financial-panel {
            .chart-item {
                display: block;
            }
            .revenue-history {
                .chart {
                    width: 100%;
                    height: 150px;
                }
            }
        }
    }

    .score-bubble {
        background-color: var(--orgaimi-color-bg2);
    }

    .legend {
        margin: 0 0 20px;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        justify-items: start;
        list-style: none;
        text-transform: uppercase;
        font-weight: bold;
        color: var(--orgaimi-color-gray40);

        li {
            display: grid;
            grid-template-columns: 1em 1fr;
            align-items: center;
        }
        li::before {
            content: "\2022";
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -0.5em;
            font-size: 2em;
        }
        li.selected::before {
            color: var(--orgaimi-color-growth);
        }
        li.average::before {
            color: var(--orgaimi-color-font);
        }
    }
}

@media (min-width: $screen-sm-min) {
    #office-panel {
        width: 550px;
    }
}
