table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 18px;
    text-align: left;
    color: #333;
}

th,
td {
    padding: 12px 15px;
    border: 1px solid #6e6a6a;
    color: #333;
}

th {
    background-color: #a19c9c;
}

tr {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}
