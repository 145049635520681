.engine-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65%;
    img {
        width: 150px;
        height: 150px;
        opacity: 0.5;
    }
    p {
        padding: 0;
        margin-top: -20px;
        font-size: 0.8em;
    }
}
