@import "../../styles/base/_variables.scss";

.salesforce-form {
    .groups {
        margin-top: pxToEm(20);
        margin-bottom: pxToEm(30);
    }
    .fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        .form-field {
            border: 1px solid var(--orgaimi-color-bg);
            &.-error {
                border-color: var(--orgaimi-color-error-border);
            }
        }
    }
    .button-group {
        grid-column: 1 / 4;
    }
}

@media (max-width: $screen-sm-max) {
    .salesforce-form {
        .fields {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
