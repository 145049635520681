@import "../../styles/base/_variables.scss";

#shared-filters,
.saved-filter {
    .filter-summary {
        margin-bottom: 20px;
        padding: pxToEm(20) 0;
        display: flex;
        flex-grow: 1;

        .filter-item {
            margin-right: 20px;
            max-width: 123px;
            padding: 0;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            align-items: center;
            border: 1px solid var(--orgaimi-color-bg2);

            font-family: "interstate-compressed", sans-serif;

            img {
                max-width: pxToEm(34);
                margin-bottom: pxToEm(8);
            }

            h4 {
                margin-bottom: 0;
                padding: 8px;
                background-color: var(--orgaimi-color-bg2);
                width: 100%;
                color: var(--orgaimi-color-font);
                text-transform: capitalize;
                text-align: center;
            }
            .values {
                margin: 10px 8px;
            }
        }

        &.-light {
            .filter-item {
                border: 1px solid var(--orgaimi-color-bg);
                h4 {
                    background-color: var(--orgaimi-color-bg);
                }
            }
        }
    }

    .saved-filters {
        display: flex;
    }

    .unsaved-filters {
        display: flex;
        flex-direction: column;
    }

    .notification-bar {
        margin-bottom: 35px;
    }

    .bucket-links {
        margin-bottom: pxToEm(40);
        display: flex;

        a {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: pxToEm(40);
            margin-right: 20px;
            cursor: pointer;
            text-decoration: none;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background-color: var(--orgaimi-color-bg-lighten);
            }

            img {
                margin-bottom: 10px;
                height: 60px;
            }

            h5 {
                margin-bottom: 0;
                color: var(--orgaimi-color-font);
            }
        }
    }
}
