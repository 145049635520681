@import "../../styles/base/_variables.scss";
@import "../../styles/base/_mixins.scss";
@import "./pagination";

.table {
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    display: grid;
    position: relative;

    //overflow-x: scroll;

    .table-settings {
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 6;
        color: var(--orgaimi-color-gray70);
        cursor: pointer;
        background-color: var(--orgaimi-color-table-header-bg);
        padding: 5px;
        border-radius: 50%;
        text-align: center;
    }

    .table-filter {
        margin-top: pxToEm(15);
        .button {
            margin: 0;
        }
    }

    .cell {
        padding: pxToEm(20) pxToEm(15);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-grow: 1;
        background-color: var(--orgaimi-color-bg2-a70);
        position: relative;
        color: var(--orgaimi-color-gray70);
        border-bottom: 1px solid var(--orgaimi-color-bg);

        transition: background-color 400ms ease-in-out;

        &::after {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            content: " ";
            border-right: 1px solid var(--orgaimi-color-bg);
            height: 70%;
            transform: translateY(-50%);
        }

        // Row Click
        &.-clickable {
            cursor: pointer;

            &::after {
                opacity: 1;
            }
        }

        &.-end::after {
            content: ">";
            padding-right: pxToEm(15);
            display: flex;
            align-items: center;
            position: absolute;
            z-index: 2;
            font-size: pxToEm(40);
            color: var(--orgaimi-color-link);
            opacity: 0;
            transition: opacity 400ms ease-in-out;
        }

        &.-hover {
            background-color: var(--orgaimi-color-bg2-a50);

            &.-end::after {
                opacity: 1;
            }
        }

        &.-end.-header::after {
            display: none;
        }

        &:last-child {
            border-right: none;
        }

        .logo {
            color: var(--orgaimi-color-font);
            text-decoration: none;
            width: 100%;

            font-family: "interstate-condensed", sans-serif;
            font-size: pxToEm(24);
            color: var(--orgaimi-color-font);
        }
        .link {
            text-decoration: none;
            color: var(--orgaimi-color-secondary-dimmed);
            transition: color 500ms;

            &:hover {
                color: var(--orgaimi-color-font);
            }
        }

        .top-potential {
            .large-number {
                h3 {
                    margin-bottom: 1px;
                    font-size: pxToEm(32);
                }
                h5 {
                    font-weight: 500;
                    font-size: pxToEm(14);
                }
            }
        }

        .form-field.-checkbox {
            justify-content: center;
            background-color: inherit;
        }

        /* Variation */
        &.-header {
            //padding: 24px 6px;
            white-space: nowrap;
            font-family: "interstate-condensed";
            text-align: center;
            background-color: var(--orgaimi-color-table-header-bg);
            justify-content: flex-start;

            //transition: top 400ms ease-in-out; TODO: Add in when filter menu slides

            &:hover {
                z-index: 5;
            }

            &:focus-within {
                z-index: 4;
            }

            .controls {
                margin-top: pxToEm(15);
            }

            .form-field {
                padding: pxToEm(5) pxToEm(5);
                input {
                    min-width: auto;
                }
            }

            .-sortable {
                cursor: pointer;
            }
        }

        &.-fade {
            opacity: 0.4;
        }

        &.-sticky-left {
            position: sticky;
            left: 0;
            //background-color: rgba(var(--orgaimi-color-bg2), 0.97);
            z-index: 2;
        }
        &.-sticky-top {
            position: sticky;
            top: 0;
            z-index: 3;
        }
    }

    .sort {
        @include no-select;
        margin-left: 3px;

        display: inline-block;
        color: var(--orgaimi-color-secondary-dimmed);
        position: relative;
        cursor: pointer;

        .svg-inline--fa {
            font-size: 14px;
        }

        .asc,
        .desc {
            position: absolute;
            top: 0;
            left: 0;
            color: var(--orgaimi-color-primary);
        }
    }

    .multi-select .label {
        min-width: 100px;
        padding-right: 20px;
    }

    .large-number {
        h3 {
            margin: 0;
            font-family: "interstate-compressed", sans-serif;
            font-size: pxToEm(40);
            font-weight: 500;
            line-height: 1;
        }
        h5 {
            color: var(--orgaimi-color-gray70);
            text-transform: none;
        }
    }
}

.shadowTable {
    height: 60vw;
    width: 100%;
    background: linear-gradient(-45deg, var(--orgaimi-color-bg2), var(--orgaimi-color-bg));
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;
}

.table-no-data {
    padding: pxToEm(50) 0;
    background-color: var(--orgaimi-color-bg2-a70);
    h4 {
        margin: 0;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
