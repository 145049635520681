@import "../../styles/base/_variables.scss";

.info-list {
    background-color: var(--orgaimi-color-bg-dimmed);
    .info-item {
        padding: 2.667em 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        text-align: left;

        &:not(:last-child) {
            border-bottom: 1px solid var(--orgaimi-color-bg2);
        }

        .content {
            flex-grow: 1;
            color: var(--orgaimi-color-font);

            h1 {
                margin: 0 0 10px;
                padding: 0;

                font-size: pxToEm(62);
                line-height: 1;
            }

            h5 {
                margin: 0;
                padding: 0;
            }
        }

        .score-bubble {
            margin: 0;
            background-color: var(--orgaimi-color-bg2);
        }
        .score-bubble.-na {
            background-color: var(--orgaimi-color-bg);
        }

        &.-reverse {
            flex-direction: row-reverse;
            .content {
                padding-left: 30px;
            }
        }
    }

    .collapsing-pills {
        display: inline-block;
        .pill-list {
            li span {
                margin: 0;
            }
        }
    }

    /* Content Variations */
    .large-number {
        h3 {
            font-family: "interstate-condensed", sans-serif;
            font-size: pxToEm(62);
            line-height: 1;
        }
        h5 {
            font-family: "interstate", sans-serif;
            font-weight: bold;
            color: #b2babe;
        }
    }
}
