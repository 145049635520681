@import "../../../styles/base/_variables.scss";

.tile-bar {
    margin-bottom: pxToEm(30);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    align-items: stretch;
    justify-content: space-between;
    height: pxToEm(20);

    .bar {
        width: 100%;
        height: 100%;
        background-color: var(--orgaimi-color-bg);
        &.-active {
            background-color: var(--orgaimi-color-secondary-dimmed);
        }
    }

    &.-primary {
        .bar.-active {
            background-color: var(--orgaimi-color-growth);
        }
    }
    &.-large {
        height: pxToEm(35);
    }
    &.-micro {
        height: pxToEm(5);
        grid-gap: 1px;
        margin-bottom: 5px;
    }
}
