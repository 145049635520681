.pagination {
    text-align: center;
    padding: 1em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    font-family: "interstate-condensed";

    .form-field {
        margin-left: 8px;
    }

    & > div {
        margin-left: 10px;
    }

    & > div:first-child {
        margin-left: 0;
    }

    .goto-page {
        text-align: center;
    }
    .page-of {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--orgaimi-color-gray60);
        .of {
            flex-grow: 1;
        }
    }
    .form-field {
        margin: 0 8px;
        padding: 0;
        flex-grow: 0;

        //background-color: var(--orgaimi-color-bg);
        //border: 2px solid var(--orgaimi-color-bg2);

        input {
            padding: 5px;
            min-width: 38px;
            width: 38px;
            text-align: center;
            //background-color: var(--orgaimi-color-bg);
        }
    }
    .multi-select {
        margin-left: 30px;
        select {
            padding: 5px;
        }
        .label {
            padding: 5px;
            min-width: 125px;
            label {
                padding: 5px;
                color: var(--orgaimi-color-gray60);
                font-weight: 300;
            }
        }
        .dropdown {
            min-width: 125px;
            transform: translateY(-100%);
            top: -1px;
        }
    }
    .page-links {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;

        li {
            margin: 0 1px;
            padding: 0 4px;
            cursor: pointer;

            &.selected {
                color: var(--orgaimi-color-primary);
            }
        }
    }
    .first,
    .previous,
    .next,
    .last {
        cursor: pointer;

        transition: color 400ms ease-in-out;

        &:hover {
            color: var(--orgaimi-color-primary);
        }
    }
}
