@import "../../../../styles/base/variables";

.potential-projects {
    margin-top: pxToEm(60);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;

    & > h3 {
        margin-top: pxToEm(40);
        grid-column: 1 / span 3;
    }

    .potential-project {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        box-shadow: var(--orgaimi-box-shadow);

        header {
            padding: pxToEm(22) pxToEm(22) pxToEm(15) pxToEm(40);
            background-color: var(--orgaimi-color-bg-dimmed);
            position: relative;

            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                margin: 0;
                font-size: pxToEm(34);
                line-height: pxToEm(34);

                span {
                    margin-top: pxToEm(5);
                    display: block;
                    font-size: pxToEm(16);
                    line-height: pxToEm(16);
                    text-transform: uppercase;
                    em {
                        color: var(--orgaimi-color-font-dimmed);
                    }
                }
            }

            a {
                font-size: pxToEm(21);
                color: var(--orgaimi-color-secondary-dimmed);
                transition: font-size 200ms ease-in-out;
                &:hover {
                    font-size: pxToEm(26);
                }
            }

            .flags .flag {
                width: 3px;
            }
        }
        .panel-content {
            flex-grow: 1;
            padding: pxToEm(22) pxToEm(20);
            background-color: var(--orgaimi-color-bg2-a70);

            text-align: center;
            color: var(--orgaimi-color-gray70);

            position: relative;

            h4 {
                margin: 0;
                font-size: pxToEm(44);
                line-height: 1;

                em {
                    font-family: "interstate-compressed", sans-serif;
                }
            }

            h5 {
                margin: 0;
                font-family: "interstate-condensed", sans-serif;
                color: var(--orgaimi-color-gray70);
                font-weight: normal;
                em {
                    font-weight: 700;
                }
            }

            hr {
                margin: pxToEm(15) 0;
                height: 2px;
            }

            /* Component Mods */
            .team-member {
                margin-top: pxToEm(16);
                h6 {
                    font-family: "interstate", sans-serif;
                    color: var(--orgaimi-color-gray70);
                }
                a {
                    color: var(--orgaimi-color-gray70);
                    text-decoration: none;
                }
            }
            .probability-bubble {
                h5 {
                    margin-top: pxToEm(6);
                }
            }

            .comments-overlay,
            .feedback-overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                overflow: hidden;

                width: 100%;
                height: 100%;
                max-height: 0%;
                background-color: var(--orgaimi-color-bg2-a92);

                transition: max-height 400ms ease-in-out;

                &.-active {
                    max-height: 100%;
                }
            }

            .feedback-overlay {
                overflow-y: auto;
            }
        }

        .dialogue-menu {
            padding: pxToEm(15) pxToEm(20);
            background-color: var(--orgaimi-color-bg2-a70);

            color: var(--orgaimi-color-gray70);
            font-size: 20px;

            display: flex;
            justify-content: space-between;

            a,
            button {
                text-decoration: none;
                transition: color 400ms ease-in-out;
                &.-active {
                    color: var(--orgaimi-color-font);
                }
            }

            .button-group {
                width: auto;

                .button:last-child {
                    margin-right: 0;
                }
            }

            .svg-inline--fa {
                font-size: 20px;
                line-height: 20px;
                vertical-align: -0.27em;
            }

            .svg {
                opacity: 0.7;
                transition: opacity 400ms ease-in-out;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .view-all {
        margin: 40px 0 0 0;
        grid-column: 1 / span 3;
        font-size: pxToEm(18);
        font-weight: bold;
        line-height: pxToEm(21);
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;

        .svg-inline--fa {
            margin-left: pxToEm(8);
            font-size: pxToEm(21);
            color: var(--orgaimi-color-font-dimmed);
        }
    }
}

@media (max-width: $screen-md-min) {
    .potential-projects {
        display: block;
        .potential-project {
            margin-bottom: pxToEm(30);
        }
    }
}
