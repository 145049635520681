/* Buttons */
.button {
    margin: 10px 0;
    padding: 1.111rem 1.667rem;
    border: none;
    font-size: 1.333rem;
    line-height: 1;
    font-family: "interstate-condensed";
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--orgaimi-color-font);
    cursor: pointer;
    white-space: nowrap;
    display: inline-block;
    transition: padding 300ms;
    position: relative;
    overflow: hidden;

    .bubble {
        position: absolute;
        top: -8px;
        right: -8px;
        background-color: var(--orgaimi-color-secondary);
        color: var(--orgaimi-color-font);
        padding: 4px;
        min-width: 20px;
        border-radius: 50%;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
    }

    span.ripple {
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        animation: ripple 500ms linear;
        background-color: rgba(var(--orgaimi-color-bg2), 0.7);
    }

    @keyframes ripple {
        to {
            transform: scale(4);
            opacity: 0;
        }
    }

    &:hover {
        text-decoration: none;
    }

    //Primary
    &.-primary {
        background: linear-gradient(to bottom, #{var(--orgaimi-color-primary)} 0%, #{var(--orgaimi-color-primary-dimmed)} 100%);
        color: var(--orgaimi-color-font);
        border: 0;
        transition: background-color ease 400ms;
        &:hover,
        &:focus {
            background: linear-gradient(to bottom, #{var(--orgaimi-color-primary-dimmed)} 0%, #{var(--orgaimi-color-primary)} 100%);
            color: var(--orgaimi-color-font);
            border-color: var(--orgaimi-color-primary);
        }

        &.-border {
            border: 1px solid var(--orgaimi-color-primary);
            color: var(--orgaimi-color-primary);
            background: #fff;
            &:hover,
            &:focus {
                color: var(--orgaimi-color-primary-dimmed);
                border-color: var(--orgaimi-color-primary-dimmed);
            }
        }

        &:active,
        &:link,
        &:visited {
            color: var(--orgaimi-color-font);
        }
    }

    //Secondary
    &.-secondary {
        background: var(--orgaimi-color-secondary);
        background: linear-gradient(to bottom, #{var(--orgaimi-color-secondary)} 0%, #{var(--orgaimi-color-secondary-dimmed)} 100%);
        color: var(--orgaimi-color-font);
        border: 0;
        transition: background-color ease 400ms;
        &:hover,
        &:focus {
            background: linear-gradient(to bottom, #{var(--orgaimi-color-secondary-dimmed)} 0%, #{var(--orgaimi-color-secondary)} 100%);
            color: var(--orgaimi-color-font);
            border-color: var(--orgaimi-color-secondary);
        }

        &.-border {
            border: 1px solid var(--orgaimi-color-secondary);
            color: var(--orgaimi-color-secondary);
            background: none;
            &:hover,
            &:focus {
                color: var(--orgaimi-color-secondary-lightened);
                border-color: var(--orgaimi-color-secondary-lightened);
            }
        }

        &:active,
        &:link,
        &:visited {
            color: var(--orgaimi-color-font);
        }
    }

    // Dark
    &.-dark {
        background: var(--orgaimi-color-bg);
        color: var(--orgaimi-color-secondary-dimmed);
        border-color: var(--orgaimi-color-bg);
        transition: background-color ease 400ms;
        &:hover,
        &:focus {
            background: var(--orgaimi-color-bg-lighten);
            border-color: var(--orgaimi-color-bg);
            color: var(--orgaimi-color-gray80);
        }

        &:active,
        &:link,
        &:visited {
            color: var(--orgaimi-color-secondary-dimmed);
        }

        &.-border {
            background-color: var(--orgaimi-color-bg2);
            border: 1px solid var(--orgaimi-color-bg);

            &.-active {
                background-color: var(--orgaimi-color-accent1);
                //border: 1px solid var(--orgaimi-color-bg);
            }
        }
    }

    // Dark
    &.-darker {
        background: var(--orgaimi-color-bg2);
        color: var(--orgaimi-color-secondary-dimmed);
        border-color: var(--orgaimi-color-bg2);
        transition: background-color ease 400ms;
        &:hover,
        &:focus {
            background: var(--orgaimi-color-bg-lighten);
            border-color: var(--orgaimi-color-bg2);
            color: var(--orgaimi-color-secondary-dimmed);
        }

        &:active,
        &:link,
        &:visited {
            color: var(--orgaimi-color-secondary-dimmed);
        }

        &.-border {
            background-color: var(--orgaimi-color-bg);
            border: 1px solid var(--orgaimi-color-bg2);
        }
    }

    //Error
    &.-error {
        background: var(--orgaimi-color-error);
        border-color: var(--orgaimi-color-error);
        color: var(--orgaimi-color-font);
        &:hover {
            background: var(--orgaimi-color-error-dimmed);
            border-color: var(--orgaimi-color-error);
            color: var(--orgaimi-color-font);
        }

        &.-border {
            border: 1px solid var(--orgaimi-color-error);
            color: var(--orgaimi-color-error);
            background: #fff;
            &:hover {
                color: var(--orgaimi-color-error-dimmed);
                border-color: var(--orgaimi-color-error-dimmed);
            }
        }
        &:active,
        &:link,
        &:visited {
            color: var(--orgaimi-color-font);
        }
    }

    //Default
    &.-default {
        border: 1px solid var(--orgaimi-color-gray40);
        color: var(--orgaimi-color-bg);
        background: var(--orgaimi-color-font);
        &:hover {
            color: var(--orgaimi-color-bg-dimmed);
            border-color: var(--orgaimi-color-font-dimmed);
            background: var(--orgaimi-color-font-dimmed);
        }
        &:active,
        &:link,
        &:visited {
            color: var(--orgaimi-color-bg);
        }

        &.-border {
            background: none;
            color: var(--orgaimi-color-font);
            border: 1px solid var(--orgaimi-color-font);
        }
    }

    .svg-inline--fa {
        font-size: 15px;
    }

    //Default Color
    @extend .-default;

    &.-disabled,
    &:disabled,
    &[disabled] {
        opacity: 0.4;
        cursor: not-allowed;
    }
    &.-inactive {
        opacity: 0.4;
    }

    &.-icon {
        line-height: 100%;

        .svg-inline--fa {
            font-size: 15px;
            vertical-align: bottom;
        }

        &.-left {
            .svg-inline--fa {
                margin-left: -15px;
                margin-right: 15px;
            }
        }
        &.-right {
            .svg-inline--fa {
                margin-left: 15px;
                margin-right: -15px;
            }
        }
    }

    &.-micro {
        padding: pxToEm(5) pxToEm(5);
        font-size: 0.889rem;
        line-height: 0.889rem;
    }
    &.-x-small {
        padding: pxToEm(10) pxToEm(15);
        font-size: 0.889rem;
        line-height: 0.889rem;
    }
    &.-small {
        padding: 0.833rem 1.667rem;
        font-size: 0.889rem;
        line-height: 0.889rem;

        .svg-inline--fa {
            font-size: 13px;
        }

        &.-icon {
            img {
                margin-right: 5px;
                vertical-align: middle;
            }
        }

        &.-left {
            .svg-inline--fa {
                margin-left: -2px;
                margin-right: 5px;
            }
        }

        &.-right {
            .svg-inline--fa {
                margin-left: 5px;
                margin-right: -5px;
            }
        }
    }
    &.-medium {
        font-size: 1.1rem;
    }
    &.-large {
        padding: 12px 65px;
        font-size: 15px;
        line-height: 15px;
    }
    &.-full {
        width: 100%;
        text-align: center;
        display: block;
    }
    &.-text {
        margin: 0;
        padding: 0;
        color: var(--orgaimi-color-link-dimmed);
        background: none;
        text-align: left;
        white-space: normal;
        font-size: 13px;
        line-height: 13px;
        border-color: transparent;

        transition: color 400ms ease-in-out;

        &.-center {
            text-align: center;
        }
        &.-selected {
            color: var(--orgaimi-color-bg);
            cursor: default;
        }
        &:hover {
            color: var(--orgaimi-color-link);
        }
    }
    &.-clear {
        color: var(--orgaimi-color-link-dimmed);
        background: none;
        text-align: left;
        white-space: normal;
        font-size: 13px;
        line-height: 13px;
        border-color: transparent;

        transition: color 400ms ease-in-out;

        &.-center {
            text-align: center;
        }
        &.-selected {
            color: var(--orgaimi-color-bg);
            cursor: default;
        }
        &:hover {
            color: var(--orgaimi-color-link);
        }
    }
    &.-default-color {
        color: var(--orgaimi-color-bg);
    }
    &.-no-pointer {
        cursor: not-allowed;
    }
    &.-export-icon {
        margin: 0;
        padding: 0;
        color: var(--orgaimi-color-link);
        background: none;
        text-align: left;
        white-space: normal;
        border-color: transparent;

        .svg-inline--fa {
            font-size: pxToEm(40);
            line-height: pxToEm(40);
        }
    }
}

.button-group {
    width: 100%;
    display: flex;
    align-items: center;

    h1,
    h2,
    h3,
    h4 {
        flex-grow: 1;
    }
    .image-stream {
        margin-right: 10px;
    }

    .button {
        margin-left: 0px;
        margin-right: 10px;

        &.-right {
            margin-left: auto;
        }
    }

    /* Variations */
    &.-right {
        justify-content: flex-end;

        .button {
            margin-left: 10px;
            margin-right: 0px;
        }
    }
    &.-center {
        justify-content: center;

        .button:last-child {
            margin-right: 0;
        }
    }
    &.-full {
        .button,
        a {
            flex-grow: 1;
            text-align: center;
        }
    }
    &.-radius {
        .button,
        a {
            border-radius: pxToEm(22);
        }
    }

    &.-stacked {
        flex-direction: column;
    }
    &.-margin-right .form-field {
        margin-right: 15px;
    }
}

.button-share {
    margin: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 20;
}

.button-list {
    margin-bottom: 20px;
    a,
    .button {
        display: inline-block;
        padding: 20px 25px;
        background-color: var(--orgaimi-color-bg2-a50);
        border: 1px solid var(--orgaimi-color-bg2);
        color: var(--orgaimi-color-secondary-dimmed);
        text-transform: uppercase;
        text-decoration: none;

        &.-active {
            background-color: var(--orgaimi-color-bg);
            border: 1px solid var(--orgaimi-color-bg2);
            color: var(--orgaimi-color-font);
        }
    }

    /* Variations */
    &.-bubble {
    }
    &.-underline {
        border-bottom: 2px solid var(--orgaimi-color-bg2);
        display: inline-block;
        a,
        .button {
            margin: 0 15px 0 0;
            padding: 0 2px 10px;
            font-size: 1.111rem;
            text-transform: uppercase;
            font-family: "interstate";
            font-weight: 700;

            background: none;
            border: none;
            border-bottom: 2px solid var(--orgaimi-color-bg2);
            color: var(--orgaimi-color-font);
            position: relative;
            bottom: -2px;

            transition: border 400ms ease-in-out;

            &:last-child {
                margin-right: 0;
            }

            &.-active {
                border-color: var(--orgaimi-color-accent1);
                color: var(--orgaimi-color-font);
            }
        }
    }
    &.-small {
        a,
        .button {
            padding: 10px 20px;
            font-size: 1rem;
            text-transform: none;
        }
    }
}
