@import "../../styles/base/_variables.scss";

@keyframes slideIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.wizard {
    width: 700px;
    height: 600px;
    position: relative;

    .wizard-widget {
        width: 100%;
        position: absolute;
        top: -100%;
        left: 50%;
        transform: translateX(-50%) translateY(0%);
        transition: top ease-in-out 500ms, transform ease-in-out 500ms;

        &.-previous {
            top: -100%;
            left: 50%;
            transform: translateX(-50%) translateY(0%);
        }
        &.-skip {
            visibility: hidden;
        }

        &.-active {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            animation: slideIn 500ms;
        }

        .wizard-options {
            display: flex;
            justify-content: center;
            .option {
                padding: pxToEm(15);
                border: 1px solid var(--orgaimi-color-accent2);
                margin: 0 pxToEm(8);
                cursor: pointer;
                align-items: stretch;

                &:hover {
                    border-color: var(--orgaimi-color-white);
                }

                h4 {
                    text-align: center;
                    text-transform: uppercase;
                }

                img {
                    margin: 0 auto;
                    width: pxToEm(200);
                    transition: width 400ms ease-in-out;
                    display: block;
                }
            }

            &.-bubble {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .option {
                    margin-bottom: 10px;
                    padding: 8px 15px;
                    color: var(--orgaimi-color-link);
                    border: 1px solid var(--orgaimi-color-link);
                    border-radius: pxToEm(40);
                    display: inline-block;
                    cursor: pointer;
                    text-align: center;
                    font-size: pxToEm(20);
                    font-weight: 500;
                    transition: color ease-in-out 300ms, border ease-in-out 300ms;

                    font-family: "interstate-condensed", sans-serif;

                    &:hover {
                        color: var(--orgaimi-color-gray80);
                        border: 1px solid var(--orgaimi-color-gray80);
                    }
                }
            }
        }

        &.-template {
            p {
                em {
                    color: var(--orgaimi-color-accent1);
                    font-style: normal;
                    font-weight: 500;
                }
            }
            h4 {
                em {
                    color: var(--orgaimi-color-gray40);
                    font-style: normal;
                }
            }

            .metrics {
                h4 {
                    margin-bottom: 2px;
                }
            }
        }

        &.-selected {
            .wizard-options {
                .option {
                    border-color: var(--orgaimi-color-gray50);
                    &:hover {
                        border-color: var(--orgaimi-color-white);
                    }

                    &.-selected {
                        border-color: var(--orgaimi-color-accent2);
                    }

                    &:not(.-selected) {
                        opacity: 0.3;
                    }
                }
            }
        }
    }

    /*.widget-target-data {
        margin: pxToEm(40) 0;

        .sort-filter {
            display: flex;
        }

        .input-group {
            flex-direction: column;
            padding: pxToEm(30);
            align-items: unset;
        }
    }*/

    .finalize {
        width: 100%;

        position: absolute;
        bottom: 0px;
    }

    .button-group {
        align-items: flex-end;
    }

    .custom-filters-wrapper {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--orgaimi-color-bg2);

        &.-active {
            display: block;
        }
    }

    .custom-filters {
        height: 100%;
        display: flex;
        flex-direction: column;

        justify-content: space-between;
    }
}
