@import "../../../styles/base/_variables.scss";
@import "../../../styles/base/_mixins.scss";

.multi-select {
    display: block;
    background-color: var(--orgaimi-color-bg2);
    border: 1px solid var(--orgaimi-color-bg);
    font-family: "interstate-condensed";
    position: relative;

    &.-error {
        border-color: var(--orgaimi-color-error-border);
    }

    .label {
        min-width: 200px;
        height: 100%;
        padding: pxToEm(14) pxToEm(22) pxToEm(13);
        padding-right: 2.2rem;
        cursor: pointer;
        border-bottom: 1px solid var(--orgaimi-color-bg);
        text-align: left;
        position: relative;
        color: var(--orgaimi-color-font);
        text-transform: uppercase;

        label,
        .label-span {
            margin-right: 3px;
            color: var(--orgaimi-color-secondary-dimmed);
            font-weight: bold;
            cursor: pointer;
        }
        .arrow {
            position: absolute;
            top: 50%;
            right: 1.111rem;
            transform: translateY(-50%);

            .svg-inline--fa {
                color: var(--orgaimi-color-secondary-dimmed);
            }
        }
    }

    .dropdown {
        min-width: 220px;
        width: 100%;
        overflow: hidden;
        list-style: none;
        display: none;
        text-align: left;
        position: absolute;
        background-color: var(--orgaimi-color-bg2);
        box-shadow: 0px 2px 30px #00000066;
        z-index: 10;

        &.-open {
            display: block;
        }

        .background-line {
            margin: 0 1.111rem;
            span {
                margin-left: 13px;
                padding: 0 5px;
            }
        }

        > ul {
            overflow: auto;
        }

        ul {
            margin: 0;
            padding: 1.111rem;
            list-style: none;
            max-height: 300px;

            ul {
                padding: 0.7rem 0;
                max-height: 100%;
            }

            li {
                list-style: none;
                padding-bottom: 12px;
                padding-left: 18px;
                position: relative;
                @include no-select;
                transition: color 300ms;
                //display: flex;

                a {
                    color: var(--orgaimi-color-secondary-dimmed);
                    text-decoration: none;
                    &.-active {
                        color: var(--orgaimi-color-secondary-lightened);
                    }
                    &:hover {
                        color: var(--orgaimi-color-secondary-lightened);
                    }
                    &:first-child {
                        flex-grow: 1;
                    }
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-right: 10px;
                }

                &.-flex {
                    display: flex;
                }
            }
        }
        .button-group {
            .button {
                margin: 0;
                flex-grow: 1;
                padding-right: 0;
                padding-left: 0;
                border: 2px solid var(--orgaimi-color-bg2);
                border-right-width: 1px;
                &:last-child {
                    border-right-width: 2px;
                }
            }
        }
    }

    /* Variants */
    &.-small {
        .label {
            font-size: pxToEm(16);
            padding: pxToEm(5);
        }
    }

    &:focus,
    &:active {
        border-color: var(--orgaimi-color-gray50);
        outline: none;
    }
}

.edit-option {
    padding-bottom: pxToEm(40);
    .form-field {
        border: 1px solid var(--orgaimi-color-bg);
    }

    #action-buttons {
        padding: pxToEm(10) pxToEm(40);
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 2px solid var(--orgaimi-color-bg);
        box-shadow: var(--orgaimi-box-shadow);
    }
}
