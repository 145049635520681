@import "../../styles/base/_variables.scss";

.breadcrumb {
    margin: 0;
    padding: pxToEm(16) 0 0 pxToEm(30);
    width: 100%;
    display: flex;
    align-items: stretch;
    line-height: pxToEm(40);

    a {
        color: var(--orgaimi-color-gray40);
        text-decoration: none;
        vertical-align: middle;
    }

    .button.back {
        margin-right: pxToEm(8);
        padding-right: pxToEm(8);
        font-weight: 500;
        font-size: pxToEm(24);
        color: var(--orgaimi-color-gray40);
        line-height: pxToEm(40);

        border-right: 1px solid var(--orgaimi-color-bg2);

        transition: color 400ms;
        &:hover {
            color: var(--orgaimi-color-secondary-dimmed);
        }

        .circle {
            margin-right: 10px;
            width: pxToEm(40);
            height: pxToEm(40);
            display: inline-block;
            border-radius: 50%;

            font-family: "interstate-compressed", sans-serif;
            font-weight: 800;
            font-size: pxToEm(30);
            line-height: pxToEm(40);
            color: var(--orgaimi-color-secondary-dimmed);
            text-align: center;

            background-color: var(--orgaimi-color-bg2-a70);
        }
    }
    ul {
        margin: 1px 0 0;
        padding: 0;
        list-style: none;
        font-size: pxToEm(20);
        font-weight: 500;

        display: flex;

        li {
            &:not(:last-child):after {
                padding: 0 10px;
                content: "›";

                color: var(--orgaimi-color-secondary-dimmed);
            }
        }
    }
}
