@import "../../../styles/base/_variables.scss";

.chart-legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.chart-pie {
    path:focus {
        outline: none;
    }
}

@media (max-width: $screen-sm-max) {
    .chart-legend {
        flex-direction: column;
    }
}
