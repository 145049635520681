.svg {
    &.-detail {
        filter: invert(42%) sepia(7%) saturate(4779%) hue-rotate(147deg) brightness(89%) contrast(87%); // Light Blue
    }
    &.-white {
        filter: invert(98%) sepia(11%) saturate(94%) hue-rotate(224deg) brightness(112%) contrast(91%); // White
    }
}

a {
    .svg {
        opacity: 0.7;
        transition: opacity 400ms ease-in-out;
        &:hover {
            opacity: 1;
        }
    }
}
