.txt-healthy,
.txt-detail {
    color: var(--orgaimi-color-health100);
}
.txt-white {
    color: var(--orgaimi-color-font);
}
.txt-unhealthy {
    color: var(--orgaimi-color-health10);
}
.txt-left {
    text-align: left;
}
.txt-right {
    text-align: right;
}
.txt-uppercase {
    text-transform: uppercase;
}
.txt-no-transform {
    text-transform: none;
}
.txt-error {
    color: var(--orgaimi-color-error);
}
.txt-info {
    color: var(--orgaimi-color-info);
}
.txt-error-light {
    color: var(--orgaimi-color-error-border);
}
.txt-not-allowed {
    cursor: not-allowed;
}
.txt-warning {
    font-style: italic;
    color: var(--orgaimi-color-warning);
}
.txt-link {
    color: var(--orgaimi-color-link);
    text-decoration: underline;
}
.txt-uppercase {
    text-transform: uppercase;
}
.txt-capitalize {
    text-transform: capitalize;
}
.txt-normal {
    font-weight: normal;
}
.txt-nowrap {
    white-space: nowrap;
}

/* Fonts */
.txt {
    &.-condensed {
        font-family: "interstate-condensed", sans-serif;
    }
    &.-compressed {
        font-family: "interstate-compressed", sans-serif;
    }

    /* weights */
    &.-extra-light {
        font-weight: 300;
    }
    &.-light {
        font-weight: 400;
    }
    &.-normal {
        font-weight: 500;
    }
    &.-bold {
        font-weight: 700;
    }
    &.-black {
        font-weight: 800;
    }

    /* Transforms */
    &.-uppercase {
        text-transform: uppercase;
    }
    &.-capitalize {
        text-transform: capitalize;
    }

    &.-cursor {
        cursor: pointer;
    }

    &.-pill {
        margin: 5px 0 0 0;
        padding: 2px 6px;
        font-size: 0.833rem;
        border: 1px solid var(--orgaimi-color-font);
        border-radius: pxToEm(40);
        display: inline-block;
    }

    /* Color */
    &.-white {
        color: var(--orgaimi-color-font);
    }
    &.-gray {
        color: var(--orgaimi-color-gray70);
    }
    &.-healthy {
        color: var(--orgaimi-color-healthy);
    }
    &.-unhealthy {
        color: var(--orgaimi-color-unhealthy);
    }
    &.-potential {
        color: var(--orgaimi-color-accent1);
    }
    &.-importance {
        color: var(--orgaimi-color-important);
        border-color: var(--orgaimi-color-important);
    }
    &.-primary {
        color: var(--orgaimi-color-accent1);
    }
    &.-secondary {
        color: var(--orgaimi-color-accent2);
    }
    &.-detail-dark {
        color: var(--orgaimi-color-secondary-dimmed);
    }
    &.-error {
        color: var(--orgaimi-color-error-border);
    }

    /* Sizes */
    &.-xsmall {
        font-size: 0.778rem;
    }
    &.-small {
        font-size: 0.889rem;
    }
    &.-medium {
        font-size: 1.111rem;
    }
    &.-large {
        font-size: 1.222rem;
    }
    &.-xlarge {
        font-size: 1.333rem;
    }
    &.-xxlarge {
        font-size: 1.556rem;
    }
    &.-xxxlarge {
        font-size: 2.222rem;
    }

    &.-left {
        text-align: left;
    }
    &.-center {
        text-align: center;
    }
    &.-right {
        text-align: right;
    }

    &.-no-margin {
        margin: 0;
    }

    &.-icon {
        padding: pxToEm(5);
        text-align: center;
        border: 1px solid var(--orgaimi-color-gray70);
        display: inline-block;
        border-radius: 50%;
        color: var(--orgaimi-color-gray70);

        .svg-inline--fa {
            font-size: pxToEm(8);
        }
    }
}

@media (max-width: $screen-sm-max) {
    /* Mobile */
    .txt {
        &.-mobile-center {
            text-align: center;
        }
    }
}

@media (max-width: $screen-md-max) {
    /* Mobile */
    .txt {
        &.-tablet-center {
            text-align: center;
        }
    }
}
