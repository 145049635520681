@import "../../styles/base/_variables.scss";

#project {
    .billing-by-team {
        margin-bottom: 40px;
    }

    .billing-over-time,
    .similar-projects {
        padding: 20px 0;
        width: 100%;
        height: 300px;
        background-color: var(--orgaimi-color-bg2);
    }

    .project-links {
        .button,
        a {
            display: inline;
            margin-right: pxToEm(15);
            img {
                width: pxToEm(42);
                vertical-align: bottom;
            }
            .svg-inline--fa {
                font-size: pxToEm(42);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

/*#project-list {
    .main-wrapper {
        padding-right: pxToEm(30);
    }
}*/
