.checkmark {
    display: inline-block;

    &:after {
        /*Add another block-level blank space*/
        content: "";
        display: block;

        /*Make it a small rectangle so the border will create an L-shape*/
        width: 7px;
        height: 14px;

        /*Add a white border on the bottom and left, creating that 'L' */
        border: solid var(--orgaimi-color-font);
        border-width: 0 2px 2px 0;

        /*Rotate the L 34 degrees to turn it into a checkmark*/
        transform: rotate(34deg);
    }
}
