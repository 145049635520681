.notification-admin {
    textarea.email-body {
        height: 300px;
    }

    .template-buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    section > div {
        margin-bottom: 1em;
    }
}
