:root {
    --orgaimi-color-primary: #fd9332;
    --orgaimi-color-primary-dimmed: #fd7e0a;
    --orgaimi-color-secondary: #18b2dc;
    --orgaimi-color-secondary-lightened: #3dc5ea;
    --orgaimi-color-secondary-dimmed: #1a7690;

    --orgaimi-color-bg: #1c3642;
    --orgaimi-color-bg-lighten: #244554;
    --orgaimi-color-bg-dimmed: rgba(28, 54, 66, 0.8);
    --orgaimi-color-bg-a90: rgba(28, 54, 66, 0.9);
    --orgaimi-color-bg2: #03202d;
    --orgaimi-color-bg2-a92: rgba(3, 32, 45, 0.92);
    --orgaimi-color-bg2-a80: rgba(3, 32, 45, 0.8);
    --orgaimi-color-bg2-a70: rgba(3, 32, 45, 0.7);
    --orgaimi-color-bg2-a50: rgba(3, 32, 45, 0.5);
    --orgaimi-color-accent1: #de653a;
    --orgaimi-color-accent1-a40: rgba(222, 101, 58, 0.4);
    --orgaimi-color-accent2: #1a7690;
    --orgaimi-color-font: #f3f3f3; //#333;
    --orgaimi-color-font-dimmed: rgba(243, 243, 243, 0.7);
    --orgaimi-color-font2: #1c3642;
    --orgaimi-color-link: #1a7690;
    --orgaimi-color-link-dimmed: rgba(26, 118, 144, 0.7);

    --orgaimi-color-healthy: #18b2dc;
    --orgaimi-color-unhealthy: #fd9332;
    --orgaimi-color-growth: #de653a;
    --orgaimi-color-important: #c3dc67;
    --orgaimi-color-important-dimmed: rgba(195, 220, 103, 0.4);

    // 0-100% Health
    --orgaimi-color-health10: var(--orgaimi-color-unhealthy);
    --orgaimi-color-health20: #da8435;
    --orgaimi-color-health30: #b87637;
    --orgaimi-color-health40: #94673a;
    --orgaimi-color-health50: #765b3c;
    --orgaimi-color-health60: #1b4e60;
    --orgaimi-color-health70: #1a6780;
    --orgaimi-color-health80: #19819e;
    --orgaimi-color-health90: #1998bc;
    --orgaimi-color-health100: var(--orgaimi-color-healthy);

    // Notification Colors
    --orgaimi-color-error-banner: #8e2324;
    --orgaimi-color-error-border: #dc6788;
    --orgaimi-color-error-bg: #f9dede;
    --orgaimi-color-error: #f9dede;
    --orgaimi-color-error-dimmed: rgba(249, 222, 222, 0.4);
    //--orgaimi-color-warning-border: var(--orgaimi-color-accent1);
    //--orgaimi-color-warning-bg: #fff1d2;
    --orgaimi-color-warning: #fff1d2;
    --orgaimi-color-info-border: #68b7dc;
    --orgaimi-color-info-bg: #e1f3f8;
    --orgaimi-color-info: #e1f3f8;
    --orgaimi-color-success-border: #c3dc67;
    //--orgaimi-color-success-bg: #e7f4e4;
    //--orgaimi-color-success: #e7f4e4;

    // Structure Elements
    --orgaimi-color-header-font: #f3f3f3;
    --orgaimi-color-header-font-a60: rgba(243, 243, 243, 0.6);
    --orgaimi-color-header-bg: #03202d;
    --orgaimi-color-header-bg-a50: rgba(3, 32, 45, 0.5);
    --orgaimi-color-table-header-bg: #03202d; //#e6e4e9;
    --orgaimi-color-divider: #1c3642;
    --orgaimi-box-shadow: 0px 2px 30px #00000066;

    // 100% Shades of Grey by opacity of white on a dark background (per designs)
    --orgaimi-color-gray10: rgba(243, 243, 243, 0.1);
    --orgaimi-color-gray20: rgba(243, 243, 243, 0.2);
    --orgaimi-color-gray30: rgba(243, 243, 243, 0.3);
    --orgaimi-color-gray40: rgba(243, 243, 243, 0.4);
    --orgaimi-color-gray50: rgba(243, 243, 243, 0.5);
    --orgaimi-color-gray60: rgba(243, 243, 243, 0.6);
    --orgaimi-color-gray70: rgba(243, 243, 243, 0.7);
    --orgaimi-color-gray80: rgba(243, 243, 243, 0.8);
    --orgaimi-color-gray90: rgba(243, 243, 243, 0.9);

    // Chart Colors
    --orgaimi-color-chart1: #b3be3b;
    --orgaimi-color-chart2: #019ca2;
    --orgaimi-color-chart3: #018c39;
    --orgaimi-color-chart4: #8c8c8c;
    --orgaimi-color-chart-other: #dddddd;

    @media (prefers-color-scheme: dark) {
        .notification-admin {
            --md-editor-background-color: blue !important;
        }

        --md-editor-background-color: blue !important;
    }
}
