@import "../../styles/base/_variables.scss";

.toggle-switch {
    display: inline-block;
    background-color: var(--orgaimi-color-bg2);
    border-radius: 30px;
    position: relative;

    .item,
    a {
        display: inline-block;
        padding: pxToEm(13) pxToEm(40);
        cursor: pointer;
        position: relative;
        z-index: 2;
        font-size: pxToEm(14);
        line-height: pxToEm(14);
        font-weight: bold;
        color: var(--orgaimi-color-font);
        text-decoration: none;
        text-transform: uppercase;

        transition: color 400ms ease-in-out;
        &:not(.-active):hover {
            color: var(--orgaimi-color-accent1);
        }
    }

    .selection {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 30px;
        width: 0px;
        height: 100%;
        background-color: var(--orgaimi-color-bg);
        border: 2px solid var(--orgaimi-color-accent1);
        transition: left 400ms ease-in-out, width 400ms ease-in-out;
        animation: fadeInFromNone 400ms ease-out;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
