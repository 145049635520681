.team-members {
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h4 {
        width: 100%;
    }

    .team-member {
        min-width: 100px;
        padding: 0 10px;

        .profile-icon-wrapper {
            position: relative;
            display: block;

            .profile-icon {
                transition: width 400ms ease-in-out, border-radius 400ms ease-in-out;

                span,
                image {
                    transition: opacity 400ms ease-in-out;
                }
            }

            .icon-menu {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                opacity: 0;
                transition: opacity 400ms ease-in-out;

                a,
                .button {
                    width: 26px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--orgaimi-color-font);
                    transition: color 400ms ease-in-out;

                    &:hover {
                        color: var(--orgaimi-color-link) !important;
                    }
                }
            }
        }

        h6 {
            margin-bottom: pxToEm(10);
            text-align: center;

            a {
                text-decoration: none;
                color: var(--orgaimi-color-font);
                transition: color 400ms ease-in-out;

                &:hover {
                    color: var(--orgaimi-color-healthy);
                }
            }
        }

        &.-inactive {
            opacity: 0.3;
        }

        &.-hoverable:hover {
            .profile-icon {
                width: 100%;
                border-radius: 5px;
                span,
                image {
                    opacity: 0;
                }
            }
            .icon-menu {
                opacity: 1;
                //background: var(--orgaimi-color-bg2);
            }
        }
    }

    .profile-icon {
        margin: 0 auto 8px;
    }

    &.-large {
        .team-member {
            h6 {
                font-size: 1.222rem;
            }
        }
    }
}
