@import "../../../../styles/base/variables";
@import "../../../../styles/base/_mixins.scss";

.subclients-content {
    .flex-header {
        .button-group {
            width: auto;
        }
        .sort-icon {
            margin-right: 10px;
            padding: pxToEm(6);
            font-size: pxToEm(26);
            color: var(--orgaimi-color-secondary-dimmed);
            cursor: pointer;
            //border: 2px solid var(--orgaimi-color-bg2);
            @include no-select;
        }
    }
    .subclients-panel {
        margin-bottom: 10px;
        position: relative;
        background-color: var(--orgaimi-color-bg2);

        &:hover {
            z-index: 2;
        }

        .theme-image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            width: 80px;
            filter: invert(16%) sepia(15%) saturate(1809%) hue-rotate(154deg) brightness(91%) contrast(88%); //Gray
        }

        .hidden-content {
            position: relative;
            overflow: hidden;
            max-height: 0px;
            transition: ease-in-out max-height 400ms;
            overflow-y: scroll;

            &.-active {
                max-height: 2000px;
            }

            & > div {
                margin: 15px 0 50px;
            }

            h4 {
                font-size: 1.2rem;
            }

            .chart {
                width: 100%;
                height: 200px;
                & > div {
                    height: 100%;
                }
            }
        }
        .button {
            margin: 0;
            padding: 0.833rem;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            opacity: 1;
            transition: opacity 400ms;

            &:hover {
                opacity: 0.8;
            }

            .svg-inline--fa {
                transition: transform 800ms ease-in-out;
                transform: rotate(0deg);
            }

            &.-active {
                .svg-inline--fa {
                    transform: rotate(180deg);
                }
            }
        }

        .small-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: pxToEm(10);
            .icon {
                margin-right: pxToEm(10);
                font-size: 0.889rem;
                line-height: 1;
            }
            h5 {
                margin-bottom: 0;
                line-height: 1;
            }
        }

        .info-list {
            background: none;
            .info-item {
                padding: 0 1.944rem 30px;

                &:first-child {
                    padding-top: 30px;
                }
            }
        }
    }

    .title {
        margin-bottom: 20px;
        padding: 20px 30px 5px 30px;
        border-bottom: 1px solid var(--orgaimi-color-bg);
        position: relative;
        z-index: 1;
    }

    .intro {
        padding: 0 40px 0 30px;
        font-size: 1.222rem;
        font-weight: 400;
    }

    .recommendation-bullets {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: pxToEm(22);
        line-height: pxToEm(30);

        li {
            margin-bottom: pxToEm(20);
            padding-left: 28px;
            font-family: "interstate";
            color: var(--orgaimi-color-gray70);
            position: relative;

            .bullet {
                display: none;
            }
        }

        &.-use-icon {
            .bullet {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                color: var(--orgaimi-color-accent1);
            }
        }
        &.-importance {
            .bullet {
                color: var(--orgaimi-color-important);
            }
        }
    }

    .dashboard-cards {
        background-color: var(--orgaimi-color-bg);
        .card {
            padding: pxToEm(30) pxToEm(20);
            background-color: var(--orgaimi-color-bg2);
        }
    }
}

.t12-revenue {
    flex-direction: column;
    .large-number {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }
}

.percent-revenue {
    flex-direction: column;
    h2 {
        font-size: pxToEm(34);
    }
}
