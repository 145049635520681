.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    &.-movable {
        .card {
            &:not(.-edit) {
                cursor: move; /* fallback: no `url()` support or images disabled */
                cursor: grab; /* W3C standards syntax, all modern browser */
            }
            &:active {
                cursor: -webkit-grabbing;
                cursor: -moz-grabbing;
                cursor: grabbing;
            }
        }
    }
    .card {
        margin: 0 1px 2px;
        padding: pxToEm(30);
        background-color: var(--orgaimi-color-bg2);
        border: 2px solid var(--orgaimi-color-bg2);
        z-index: 2;

        &.-span2 {
            grid-column: span 2;
        }
        &.-span4 {
            grid-column: span 4;
        }

        h5 {
            text-transform: uppercase;
            color: var(--orgaimi-color-gray70);
            font-family: "interstate-condensed", sans-serif;
            font-weight: normal;
        }

        &.-center {
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        &.-clear {
            margin: 0;
            padding: 0;
            background: none;
            border: none;

            .dashboard-cards {
                height: 100%;
            }
        }

        &.-border-light {
            border-color: var(--orgaimi-color-bg);
        }

        &.-empty {
            padding: pxToEm(20);
            background-color: var(--orgaimi-color-bg);
            border-color: var(--orgaimi-color-bg);
            cursor: default;
            z-index: 1;
            @include no-select;
        }

        &.-drop-zone {
            border: 2px dashed var(--orgaimi-color-bg2);
        }
        &.-drop-active {
            border-color: var(--orgaimi-color-secondary);
        }
        &.-crm {
            .manager-box {
                padding: 0;
            }
            .manager {
                img {
                    margin-bottom: pxToEm(15);
                    display: block;
                }
                text-align: center;
                display: flex;
                flex-direction: column;
            }
        }
        &.-office {
            text-align: center;
            align-items: center;
            display: flex;
            flex-direction: column;

            .icon {
                margin-top: 0;
                font-size: pxToEm(56);
                color: var(--orgaimi-color-gray50);
            }
            h3 {
                font-size: pxToEm(20);
            }
        }

        &.-value-change {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .large-number h3 {
                font-size: pxToEm(48);
            }
        }

        .chart-radar {
            width: 100%;
            height: 400px;
        }

        .bubbles {
            display: flex;
            justify-content: center;
            .score-bubble {
                margin: 0 pxToEm(15);
            }
        }

        &.-header {
            margin-top: pxToEm(30);
            padding: pxToEm(25) pxToEm(30);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            grid-column: 1 / span 4;

            h2 {
                flex-grow: 1;
                margin: 0;
            }
            h3 {
                margin: 0 pxToEm(20) 0 0;
                font-size: pxToEm(40);
            }

            .external-legend {
                display: flex;
                .external-label {
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .top5 {
        box-shadow: none;
        background: none;
    }

    .manager-box {
        background: none;
    }
}

@media (max-width: 1650px) {
    .dashboard-cards {
        .chart-legend {
            flex-direction: column;
        }
    }
}

@media (max-width: $screen-md-max) {
    .dashboard-cards {
        grid-template-columns: 1fr 1fr;
        .card {
            &.-span2 {
                grid-column: span 2;
            }
            &.-span4 {
                grid-column: span 1;
            }

            &.-header {
                grid-column: 1 / span 2;
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .dashboard-cards {
        grid-template-columns: 1fr;
        .card {
            &.-span2 {
                grid-column: span 1;
            }
            &.-span4 {
                grid-column: span 1;
            }
            &.-header {
                grid-column: 1 / span 1;
            }
        }
    }
}

@media print {
    .dashboard-cards {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .card {
            grid-column: span 2;
            &.-span2 {
                grid-column: span 4;
            }
            &.-span4 {
                grid-column: span 4;
            }
            &.-header {
                grid-column: 1 / span 4;
            }
        }
    }
}
