@import "../../styles/base/_variables.scss";

.viewing-for {
    padding: pxToEm(20);
    font-size: pxToEm(18);
    position: relative;

    .filter-count {
        margin-top: 5px;
        display: flex;
        align-content: flex-start;
        text-align: left;
    }

    p {
        margin: 0;
        flex-grow: 1;
        font-size: pxToEm(18);
        color: var(--orgaimi-color-secondary);
    }

    b {
        padding: 0 4px;
        font-family: "interstate-compressed", sans-serif;
    }

    span {
        color: var(--orgaimi-color-secondary-dimmed);
        text-transform: uppercase;
        font-family: "interstate-condensed", sans-serif;
    }

    img {
        margin-right: 2px;
    }
}

.manager-box {
    min-height: pxToEm(154);
    padding: 20px 35px;
    display: flex;
    background-color: var(--orgaimi-color-bg2-a50);

    .manager {
        display: flex;
        align-items: center;
        flex-grow: 1;
        a {
            text-decoration: none;
        }
        img {
            max-width: pxToEm(64);
            height: pxToEm(64);
        }
        p {
            flex-grow: 1;
        }
        h4 {
            margin-bottom: 5px;
            color: var(--orgaimi-color-font);

            font-family: "interstate";
            font-weight: normal;
            font-size: 1.222rem;
            line-height: 1;
            text-transform: none;
        }
        h5 {
            margin: 0;
            color: var(--orgaimi-color-secondary-dimmed);
            font-family: "interstate-condensed";
            font-weight: normal;
            font-size: 1.056rem;
            line-height: 1;
            text-transform: none;
        }
    }

    .client-count {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-size: 0.889rem;
        text-align: center;

        .count {
            margin-top: 6px;
            color: var(--orgaimi-color-font);
            white-space: nowrap;
        }
    }
}
