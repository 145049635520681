@import "../../styles/base/_variables.scss";
@import "../../styles/base/_mixins.scss";

@media (min-width: $screen-md-min) {
    .segment-summary {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
    }
}
