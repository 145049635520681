@import "../../styles/base/_variables.scss";
@import "../../styles/base/_mixins.scss";

.team-list {
    .chart-column-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .chart-line-simple {
            margin-right: pxToEm(20);
            height: 100px;
        }
    }
    .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80vh;
    }
}

#team {
    .page-content {
        padding: pxToEm(22) 0 30px;
        display: block;
    }

    .team-nav {
        margin: 0 pxToEm(30) pxToEm(18);
        display: flex;
        justify-content: space-between;

        .mobile-team-nav {
            display: none;
        }

        .button-list {
            margin-bottom: 0;

            a {
                font-size: pxToEm(20);
                line-height: pxToEm(20);
                font-weight: 700;
                color: --orgaimi-color-gray60;

                &.-active {
                    color: var(--orgaimi-color-font);
                }
            }
        }
        .button {
            justify-self: flex-end;
            font-size: pxToEm(18);
            line-height: pxToEm(24);
            color: var(--orgaimi-color-secondary-dimmed);

            .svg-inline--fa {
                font-size: pxToEm(24);
                color: var(--orgaimi-color-gray60);
            }
        }
    }

    .outlet {
        padding: pxToEm(40) pxToEm(50);
    }
}

@media (max-width: $screen-md-min) {
    #team {
        .team-nav {
            .mobile-team-nav {
                display: block;
            }
            .button-list {
                display: none;
            }
        }
    }
}
