@import "../../styles/base/_variables.scss";

$inner-circle-width-sm: 30px;
$inner-circle-width-md: 40px;
$inner-circle-width-lg: 44px;
$outer-circle-width: 56px;

.probability-bubble {
    text-align: center;
    h5,
    h4 {
        margin-top: pxToEm(8);
    }
}

.probability-circle {
    margin: 0 auto;
    width: $outer-circle-width;
    height: $outer-circle-width;
    background-color: var(--orgaimi-color-accent1-a40);
    border-radius: 50%;

    position: relative;

    //animation: growIn 800ms ease-out;

    div {
        width: $inner-circle-width-lg;
        height: $inner-circle-width-lg;
        line-height: $inner-circle-width-lg;
        background-color: var(--orgaimi-color-accent1);
        box-sizing: content-box;
        border-radius: 50%;
        display: inline-block;

        font-family: "interstate-compressed", sans-serif;
        font-size: pxToEm(17);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    /* Variation */
    &.-low {
        div {
            width: $inner-circle-width-sm;
            height: $inner-circle-width-sm;
            line-height: $inner-circle-width-sm;
        }
    }
    &.-medium {
        div {
            width: $inner-circle-width-md;
            height: $inner-circle-width-md;
            line-height: $inner-circle-width-md;
        }
    }
    &.-high {
        div {
            width: $inner-circle-width-lg;
            height: $inner-circle-width-lg;
            line-height: $inner-circle-width-lg;
        }
    }
    &.-importance {
        color: var(--orgaimi-color-bg);
        background-color: var(--orgaimi-color-important-dimmed);
        div {
            background-color: var(--orgaimi-color-important);
        }
    }
}

@keyframes growIn {
    0% {
        width: 0px;
        height: 0px;
    }

    100% {
        width: $outer-circle-width;
        height: $outer-circle-width;
    }
}
