@import "../../../styles/base/_variables.scss";
@import "../../../styles/base/_mixins.scss";

.typeahead {
    display: block;
    background-color: var(--orgaimi-color-bg2);
    border: 1px solid var(--orgaimi-color-bg);
    font-family: "interstate-condensed";
    position: relative;

    .typeahead-message {
        text-align: center;
        padding: 5px 0;
    }

    .input {
        min-width: 200px;
        padding: pxToEm(18) pxToEm(22) pxToEm(15);
        cursor: pointer;
        border-bottom: 1px solid var(--orgaimi-color-bg);
        text-align: left;
        position: relative;
        display: flex;

        label {
            margin-right: 3px;
            color: var(--orgaimi-color-secondary-dimmed);
            font-weight: bold;
            cursor: pointer;
            text-transform: uppercase;
        }

        input {
            flex-grow: 1;
            background: none;
            border: none;

            color: var(--orgaimi-color-font);

            &:focus {
                outline: none;
            }
            &::placeholder {
                color: var(--orgaimi-color-font);
                font-weight: 700;
            }
        }
        .arrow {
            .svg-inline--fa {
                color: var(--orgaimi-color-secondary-dimmed);
            }
        }
    }

    .dropdown {
        min-width: 250px;
        max-height: 340px;
        width: 100%;
        list-style: none;
        display: none;
        text-align: left;
        position: absolute;
        background-color: var(--orgaimi-color-bg2);
        box-shadow: 0px 2px 30px #00000066;
        z-index: 10;

        &.-open {
            display: flex;
            flex-direction: column;
        }

        .background-line {
            margin: 20px 1.111rem 0;
            span {
                margin-left: 13px;
                padding: 0 5px;
            }
        }

        > ul {
            overflow: auto;
        }

        ul {
            margin: 0 0 14px;
            padding: 1.111rem;
            list-style: none;
            max-height: 193px;
            min-height: 75px;
            overflow-y: scroll;
            li {
                list-style: none;
                @include no-select;
                transition: color 300ms;

                a {
                    display: block;
                    position: relative;
                    margin-bottom: 12px;
                    padding-left: 18px;
                    color: var(--orgaimi-color-secondary-dimmed);
                    text-decoration: none;

                    &:hover,
                    &.-active {
                        color: var(--orgaimi-color-secondary-lightened);
                    }
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-right: 10px;
                }
            }
        }

        .button-group {
            .button {
                margin: 0 0 0 1px;
                width: auto;
                flex-grow: 1;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    /* Variations */
    &.-full {
        width: 100%;
    }
}
