.row {
    display: flex;

    .column {
        padding: 10px;
    }
}

@media (max-width: $screen-xs-max) {
    .row {
        // Variations
        &.-mobile-reverse {
            flex-direction: column-reverse;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .row {
        align-items: stretch;

        .column {
            flex-grow: 1;
            /* Grid */
            &.-span1 {
                width: calc(1px / 12px * 100%);
            }
            &.-span2 {
                width: calc(2px / 12px * 100%);
            }
            &.-span3 {
                width: calc(3px / 12px * 100%);
            }
            &.-span4 {
                width: calc(4px / 12px * 100%);
            }
            &.-span5 {
                width: calc(5px / 12px * 100%);
            }
            &.-span6 {
                width: calc(6px / 12px * 100%);
            }
            &.-span7 {
                width: calc(7px / 12px * 100%);
            }
            &.-span8 {
                width: calc(8px / 12px * 100%);
            }
            &.-span9 {
                width: calc(9px / 12px * 100%);
            }
            &.-span10 {
                width: calc(10px / 12px * 100%);
            }
            &.-span11 {
                width: calc(11px / 12px * 100%);
            }
            &.-span12 {
                width: calc(12px / 12px * 100%);
            }
        }
    }
}
