@import "../../../styles/base/_variables.scss";

.revenue-stat-list {
    padding: 0 pxToEm(20);
    display: flex;
    justify-items: flex-end;

    .stat {
        padding: 0 pxToEm(15);
        border-left: 1px solid var(--orgaimi-color-bg2);

        &:first-child {
            border: none;
        }
    }

    h4 {
        text-transform: uppercase;
    }

    .large-number {
        margin-right: pxToEm(10);
        h3 {
            margin-bottom: 0;
        }
    }
}

.stats {
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow {
        margin-right: 2px;
        .svg-inline--fa {
            color: var(--orgaimi-color-accent1);
        }
    }

    h3 {
        margin-bottom: 0;
    }
}
