@import "../../styles/base/_variables.scss";

.filter-box {
    padding: pxToEm(20) 0;
    display: flex;
    flex-grow: 1;

    .filter-item {
        max-width: 123px;
        padding: 0 12px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        border-left: 1px solid var(--orgaimi-color-bg2);

        font-family: "interstate-compressed", sans-serif;

        img {
            max-width: pxToEm(34);
            margin-bottom: pxToEm(8);
        }
        span {
            color: var(--orgaimi-color-secondary-dimmed);
            text-transform: uppercase;
        }

        b {
            font-family: "interstate-condensed", sans-serif;
        }
    }
}
