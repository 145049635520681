.recharts-cartesian-axis-tick-value {
    font-family: "interstate-condensed", sans-serif;
    font-size: pxToEm(14);
    line-height: pxToEm(14);
    font-weight: 300;
    fill: var(--orgaimi-color-gray70);
}

.recharts-tooltip-wrapper {
    border: none;
    color: var(--orgaimi-color-font);

    .chart-tooltip {
        padding: pxToEm(10);
        display: flex;
        flex-direction: column;
        background-color: var(--orgaimi-color-bg2);
        border: 2px solid var(--orgaimi-color-gray40);
        position: relative;
        z-index: 999;

        &.-center {
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        &.-dark {
            background-color: var(--orgaimi-color-bg2);
        }
        &.-healthy {
            border: 2px solid var(--orgaimi-color-healthy);
        }
        &.-unhealthy {
            border: 2px solid var(--orgaimi-color-primary);
        }

        h5,
        h4 {
            margin: 0;
        }
    }
}

.custom-tooltip {
    padding: pxToEm(10);
    display: flex;
    flex-direction: column;
    background-color: var(--orgaimi-color-bg2);
    border: 2px solid var(--orgaimi-color-gray60);

    .list {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > :first-child {
            margin-right: 10px;
        }
    }
}

.recharts-polar-angle-axis-tick-value {
    font-family: "interstate-compressed", sans-serif;
    font-size: pxToEm(18);
    font-weight: 500;
    color: rgba(243, 243, 243, 0.79);
}
