// Rem Converter
@function pxToEm($value) {
    @return calc($value / 18) + rem;
}

$header-height: pxToEm(66);
$filter-closed-height: pxToEm(87);
$filter-open-height: pxToEm(161);

//Media Queries
$max-width: 1920px;
$screen-lg-min: 1200px;
$screen-md-min: 992px;
$screen-sm-min: 768px;
$screen-xs-min: 580px;
$screen-xxs-min: 400px;

$screen-md-max: $screen-lg-min - 1;
$screen-sm-max: $screen-md-min - 1;
$screen-xs-max: $screen-sm-min - 1;
