.critical-error {
    height: 80vh;
    width: 100%;
    display: grid;
    justify-items: center;
    align-content: center;
    text-align: center;

    img {
        margin-bottom: 2rem;
    }
}
