@import "../../../styles/base/_variables.scss";

.semi-circle-pie {
    display: inline-block;
    position: relative;

    h2 {
        margin: 0 0 4px 0;
        line-height: 1;
        position: absolute;
        bottom: 0px;
        left: 50%;
        font-size: pxToEm(50);
        line-height: pxToEm(50);
        font-family: "interstate-compressed", sans-serif;
        font-weight: 800;
        transform: translateX(-50%);
        sup {
            font-weight: 400;
        }
    }

    .recharts-wrapper {
        z-index: 3;
    }
}
