@import "../../styles/base/_variables.scss";

#help {
    line-height: pxToEm(22);

    .main {
        display: flex;

        main,
        aside {
            padding-top: pxToEm(30);
        }
    }

    ul,
    ol {
        li {
            margin-bottom: pxToEm(10);
            position: relative;

            &[data-number]:before {
                content: attr(data-number);
                width: pxToEm(22);
                height: pxToEm(22);
                line-height: pxToEm(22);
                text-align: center;
                background-color: #ff0000;
                font-size: pxToEm(15);
                font-family: "interstate", sans-serif;
                color: var(--orgaimi-color-font);
                border-radius: 50%;
                display: inline-block;
                font-weight: bold;

                position: absolute;
                top: pxToEm(-1);
                left: pxToEm(-29);
            }

            ul,
            ol {
                margin-top: pxToEm(10);
            }
        }
    }

    .help-images {
        display: flex;
        align-items: center;

        img {
            border: 2px solid var(--orgaimi-color-gray20);
            display: block;
            width: 100%;
            height: auto;
        }

        div {
            margin-right: 20px;
        }
        div:last-child {
            margin-right: 0;
        }

        /* Versions */
        &.-stacked {
            flex-direction: column;
            div {
                margin: 0 0 20px 0;
            }
            div:last-child {
                margin: 0;
            }
        }
    }

    .help-section {
        margin: pxToEm(40) 0 pxToEm(80);
        max-width: 900px;
    }

    .table-grid {
        display: grid;
        grid-template-columns: auto 1fr;
        border: 1px solid var(--orgaimi-color-font);
        border-top: 0;
        .cell {
            padding: 10px;
            border-top: 1px solid var(--orgaimi-color-font);

            &:nth-child(odd) {
                border-right: 1px solid var(--orgaimi-color-font);
                justify-content: center;
                display: flex;
                align-items: center;
                h5 {
                    margin: 0;
                }
            }
        }
    }

    aside {
        background-color: var(--orgaimi-color-bg2);
    }

    .circle {
        width: pxToEm(22);
        height: pxToEm(22);
        line-height: pxToEm(22);
        text-align: center;
        background-color: #ff0000;
        font-size: pxToEm(15);
        font-family: "interstate", sans-serif;
        color: var(--orgaimi-color-font);
        border-radius: 50%;
        display: inline-block;
        font-weight: bold;
    }
}

.docs-navigation {
    position: sticky;
    top: $filter-closed-height;
    width: pxToEm(440);
    overflow-y: scroll;
    height: calc(100vh - $filter-closed-height - $header-height);

    ul {
        margin: 0;
        padding: 0 pxToEm(30);
        list-style-type: none;

        li {
            font-weight: bold;

            li {
                font-weight: normal;
            }
        }
    }

    & > ul > li {
        margin-bottom: pxToEm(8);

        ul {
            margin-top: pxToEm(8);
        }

        li {
            margin-bottom: pxToEm(4);
        }
    }

    a {
        text-decoration: none;
        color: var(--orgaimi-color-font);
    }
}

#modal {
    #help {
        [data-number]:before {
            visibility: hidden;
        }
    }

    .help-section {
        margin: 0;
    }
}

.help-accordion {
    .title {
        margin-bottom: 20px;
        padding: 30px 30px 5px 30px;
        border-bottom: 1px solid var(--orgaimi-color-bg);
        position: relative;
        display: flex;
        z-index: 1;

        h3 {
            flex-grow: 1;
        }
        .button.btn-accordion {
            margin: 0;
            padding: 0.833rem;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            opacity: 1;
            transition: opacity 400ms;

            &:hover {
                opacity: 0.8;
            }

            .svg-inline--fa {
                font-size: pxToEm(36);
                transition: transform 800ms ease-in-out;
                transform: rotate(0deg);
            }

            &.-active {
                .svg-inline--fa {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .hidden-content {
        position: relative;
        overflow: hidden;
        max-height: 0px;
        transition: ease-in-out max-height 400ms;

        &.-active {
            max-height: 1000px;
        }

        & > div {
            padding: 15px 30px 50px;
        }

        h4 {
            font-size: 1.2rem;
        }

        .chart {
            width: 100%;
            height: 200px;
            & > div {
                height: 100%;
            }
        }

        [data-number]:before {
            visibility: hidden;
        }
    }
}

@media (max-width: $screen-md-max) {
    #help {
        .main {
            flex-direction: column;

            aside {
                margin-bottom: 30px;
            }
        }
    }
}
