.form-field {
    padding: pxToEm(13) pxToEm(22) pxToEm(13);
    background-color: var(--orgaimi-color-bg2);
    display: flex;
    align-items: center;
    font-family: "interstate-condensed", sans-serif;

    &.-error {
        border-color: var(--orgaimi-color-error-border);
    }

    label,
    .label-span {
        margin-right: 3px;
        color: var(--orgaimi-color-gray50);
        font-weight: 300;
        flex-grow: 0;
        white-space: nowrap;
    }

    select,
    input,
    textarea {
        background-color: var(--orgaimi-color-bg2);
        border: none;
        color: var(--orgaimi-color-font);
        font-family: "interstate-condensed", sans-serif;
        min-width: 100px;
        flex-grow: 1;

        &:focus {
            outline: none;
        }

        &:not(:focus):invalid {
            background-color: 4px solid var(--orgaimi-color-error-border);
        }

        &::placeholder {
            color: var(--orgaimi-color-gray50);
        }
    }

    /* Variations */
    &.-checkbox,
    &.-radio {
        label,
        .label-span {
            cursor: pointer;
            user-select: none;
        }
        .check {
            position: relative;
            flex-grow: 0;
            min-width: auto;
            margin-right: pxToEm(10);
            font-size: 25px;
            line-height: 25px;

            input {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                z-index: 2;
                cursor: pointer;
                opacity: 0;
            }

            .svg-inline--fa {
                color: var(--orgaimi-color-secondary-dimmed) k;
                user-select: none;
            }

            &.-selected {
                .svg-inline--fa {
                    color: var(--orgaimi-color-font);
                }
            }
        }

        label {
            margin-left: 10px;
        }

        &:last-child {
            .check {
                margin-right: 0;
            }
        }
    }
    &.-small {
        padding: 12px 10px;
    }

    &.-match-select {
        border: 1px solid var(--orgaimi-color-bg);
        input,
        textarea {
            padding: 0;
        }
        label {
            color: var(--orgaimi-color-secondary-dimmed);
            font-weight: bold;
        }
    }

    &.-border {
        padding: 0;
        border: 1px solid var(--orgaimi-color-bg);
        input,
        textarea {
            width: 100%;
            padding: 22px 20px;
        }
    }
    &.-underline {
        padding: 0;
        border-bottom: 1px solid var(--orgaimi-color-bg);
        input,
        textarea {
            width: 100%;
            padding: 22px 20px;
        }
    }
    &.-full {
        & > div {
            width: 100%;
        }
    }
}

.radio-group {
    width: 100%;
    display: flex;
    .form-field.-radio {
        flex-grow: 1;
    }
}

.input-group {
    display: flex;
    align-items: center;

    .form-field {
        margin-right: 1px;
    }

    &.-light {
        .form-field,
        input {
            background-color: var(--orgaimi-color-bg);
        }
    }
}

form {
    .groups {
        margin: 20px;
    }
}
