@import "../../styles/base/_variables.scss";

.top5 {
    margin-bottom: 30px;
    box-shadow: var(--orgaimi-box-shadow);
    background-color: var(--orgaimi-color-bg2);
    header {
        padding: pxToEm(22) pxToEm(22) pxToEm(15) pxToEm(40);
        background-color: var(--orgaimi-color-bg-dimmed);
        position: relative;
        background-color: var(--orgaimi-color-bg);

        h4 {
            color: var(--orgaimi-color-font);
        }
    }
    ul,
    ol {
        text-align: left;
        padding: pxToEm(22) pxToEm(22) pxToEm(15) pxToEm(40);
        li {
            margin-bottom: pxToEm(22);
            padding-bottom: pxToEm(22);
            list-style: none;
            position: relative;
            display: flex;
            align-items: center;
            .title-column {
                margin-left: 0;
                flex-grow: 1;

                h5 {
                    margin-bottom: 0;
                }
            }

            border-bottom: 1px solid var(--orgaimi-color-bg);

            &:last-child {
                border-bottom: none;
            }

            &[data-number]:before {
                content: attr(data-number);
                min-width: pxToEm(22);
                width: pxToEm(22);
                height: pxToEm(22);
                line-height: pxToEm(22);
                text-align: center;
                background-color: var(--orgaimi-color-primary);
                font-size: pxToEm(15);
                font-family: "interstate", sans-serif;
                color: var(--orgaimi-color-font);
                border-radius: 50%;
                display: inline-block;
                font-weight: bold;
                position: relative;
                left: -11px;
            }

            ul,
            ol {
                margin-top: pxToEm(10);
            }

            & > div {
                margin-left: pxToEm(10);
            }

            .large-number {
                h3 {
                    margin: 0;
                }
            }
            .score-bubble {
                margin: 0;
            }
        }
    }
    .filter-display {
        display: flex;
        justify-content: space-around;
    }
}
