[data-tooltip] {
    cursor: help;
    position: relative;
}

[data-tooltip]:before {
    content: attr(data-tooltip);
    position: absolute;
    left: 50%;

    opacity: 0;
    transition: all 500ms ease-in-out;
    padding: 10px;
    transform: translateY(0%) translateX(-50%) scale(0);
    color: var(--orgaimi-color-font);
    border-radius: 10px;
    box-shadow: var(--orgaimi-box-shadow);
    font-size: pxToEm(18);
    font-family: "interstate", sans-serif;
    font-weight: normal;
    line-height: pxToEm(22);
    text-transform: none;
    text-align: center;
    white-space: normal;
    width: 100%;
    min-width: 200px;
    max-width: 400px;
    z-index: 999;
}

[data-tooltip]:hover:before {
    opacity: 1;
    background: var(--orgaimi-color-bg);
    border: 2px solid var(--orgaimi-color-gray50);
}

// HOVER Down
[data-tooltip]:not(.tooltip--up):not(.tooltip--center):before {
    bottom: -5px;
}
[data-tooltip]:not(.tooltip--up):not(.tooltip--center):hover:before {
    bottom: -5px;
    transform: translateY(100%) translateX(-50%) scale(1);
}

[data-tooltip].tooltip--center:before {
    transform: translateY(100%) translateX(-50%) scale(0);
}
// HOVER CENTER
.tooltip--center[data-tooltip]:hover:before {
    transform: translateY(100%) translateX(-50%) scale(1);
}

//UP
[data-tooltip].tooltip--up:before {
    top: 0;
}
// HOVER Up
.tooltip--up[data-tooltip]:hover:before {
    transform: translateY(-100%) translateX(-50%) scale(1);
}

[data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
}
