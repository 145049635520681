@import "../../../../styles/base/variables";

.filter-list {
    margin-top: pxToEm(40);

    .saved-filter {
        h3 {
            flex-grow: 1;
            margin-bottom: pxToEm(5);
        }
        .flex-header {
            margin: 0;
        }
        .button-group {
            width: auto;
        }
        .filter-summary {
            margin-bottom: pxToEm(30);
        }
    }
    .filter-item {
        padding: pxToEm(20);
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--orgaimi-color-bg2);
    }
}
