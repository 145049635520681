@import "../../styles/base/_variables.scss";

.comments-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .comment-list {
        padding: pxToEm(20);
        flex-grow: 1;
        text-align: left;
        overflow-y: scroll;
        height: 100%;

        &.-no-comments {
            display: flex;
            align-items: center;

            h5 {
                width: 100%;

                font-size: pxToEm(24);
                text-align: center;
                text-transform: none;
            }
        }

        .comment {
            padding: pxToEm(15) 0;
            display: flex;

            .profile-icon {
                margin-right: 20px;
                min-width: pxToEm(64);
                width: pxToEm(64);
                height: pxToEm(64);
                line-height: pxToEm(64);
                font-size: pxToEm(30);
            }

            border-bottom: 1px solid var(--orgaimi-color-bg);

            .content {
                flex-grow: 1;

                p {
                    margin: pxToEm(10) 0 0;
                }

                .name-date {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    .form-field {
        flex-grow: 1;
        background-color: var(--orgaimi-color-bg);

        input {
            background-color: var(--orgaimi-color-bg);
        }
    }

    .add-comment {
        padding: pxToEm(20);
    }

    .button-group {
        overflow: hidden;
        border-radius: 22px;
        button.-small {
            margin: 0;
            flex-grow: 0;
        }
    }
}

.modal.comments-modal {
    padding: pxToEm(80) 0 0 0;
}
