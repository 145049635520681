.expert-in {
    margin-bottom: pxToEm(10);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    & > div {
        span {
            opacity: 0.2;
        }

        &.-active {
            span {
                opacity: 1;
            }
        }
    }
}
