.pill-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    li > span {
        margin-bottom: 10px;
        padding: 2px 6px;
        font-size: 0.833rem;
        border: 1px solid var(--orgaimi-color-gray50);
        border-radius: pxToEm(40);
        display: inline-block;

        font-family: "interstate-condensed", sans-serif;

        .svg-inline--fa {
            color: inherit;
        }
    }
    .icon {
        margin-right: 5px;
        //padding: 4px;
        font-size: 15px;
        display: inline-block;
    }

    li.-importance > span {
        border-color: var(--orgaimi-color-important);
        color: var(--orgaimi-color-important);
    }

    /*li.-independence > span {
        border-color: var(--orgaimi-color-warning);
        color: var(--orgaimi-color-warning);
    }*/
}
