@import "../../../styles/base/variables";

.bar-comparison {
    width: 100%;
    padding-bottom: 2em;
    h4 {
        margin-bottom: pxToEm(10);
        //color: var(--orgaimi-color-font2);
    }

    .bar-container {
        margin: 0 0 pxToEm(25) 0;
        display: grid;
        grid-template-columns: 6fr 1fr;
        align-items: flex-end;
        font-family: "interstate-condensed";
        font-size: pxToEm(20);
        line-height: pxToEm(20);

        .bar-bg {
            margin-right: pxToEm(12);
            background-color: var(--orgaimi-color-bg);
        }
        .bar {
            height: 18px;
            width: 0;
            transition: width 1.3s;
        }
        &.-top {
            color: var(--orgaimi-color-growth);
        }
        &.-top .bar {
            background-color: var(--orgaimi-color-growth);
        }
    }
}
