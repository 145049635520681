@import "../../styles/base/_variables.scss";

#admin {
    line-height: pxToEm(22);

    .main {
        padding-top: 0;
        max-width: 100vw;
        display: grid;
        grid-template-columns: 200px 1fr;

        aside,
        main {
            padding-top: $filter-closed-height;
        }
    }
}

.side-navigation {
    position: sticky;
    top: $filter-closed-height;
    width: pxToEm(440);
    overflow-y: scroll;
    height: calc(100vh - $filter-closed-height - $header-height);

    ul {
        margin: 0;
        padding: 0 pxToEm(30);
        list-style-type: none;

        li {
            font-weight: bold;

            li {
                font-weight: normal;
            }
        }
    }

    & > ul > li {
        margin-bottom: pxToEm(8);

        ul {
            margin-top: pxToEm(8);
        }

        li {
            margin-bottom: pxToEm(4);
        }
    }

    a {
        text-decoration: none;
        color: var(--orgaimi-color-font);
    }
}
