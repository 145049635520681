@import "../../styles/base/_variables.scss";

$icon-small: pxToEm(31);
$icon-medium: 4.33rem;

.profile-icon {
    width: 9.722rem;
    height: 9.722rem;
    margin-bottom: 10px;
    position: relative;

    color: var(--orgaimi-color-font);
    font-family: "interstate";
    font-weight: bold;
    font-size: 6.4rem;
    line-height: 9.722rem;
    border: 2px solid var(--orgaimi-color-accent1);
    border-radius: 50%;
    text-align: center;
    overflow: hidden;

    .svg-inline--fa {
        font-size: 95%;
    }

    .image-override {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    /* Variations */
    &.-client {
        border-color: var(--orgaimi-color-font);
    }
    &.-center {
        margin: 0 auto;
    }
    &.-faded {
        border-color: var(--orgaimi-color-gray50);
        color: var(--orgaimi-color-gray50);
    }

    /* Sizes */
    &.-small {
        width: $icon-small;
        height: $icon-small;
        line-height: pxToEm(30); // Fixes centering glitch at that size
        font-size: pxToEm(17);
        font-family: "interstate-condensed";
        font-weight: 500;
        border-width: 1px;
    }
    &.-medium {
        width: $icon-medium;
        height: $icon-medium;
        line-height: $icon-medium;
        font-size: calc($icon-medium / 2);
    }
}
