.notification-bar {
    margin: 5px 0;
    padding: pxToEm(15);
    border-style: solid;
    border-width: 0 0 0 10px;
    font-size: pxToEm(18);
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--orgaimi-color-font);
    background-color: var(--orgaimi-color-bg2);

    .icon {
        margin-left: 10px;
        font-size: pxToEm(30);
        line-height: pxToEm(30);
        color: var(--orgaimi-color-bg);
    }
    .svg-inline--fa {
        font-size: inherit;
    }
    p {
        margin: 0;
        flex-grow: 1;
    }

    .btn-close {
        cursor: pointer;
        position: absolute;
        top: pxToEm(20);
        right: pxToEm(15);
        z-index: 2;
        padding: 3px;

        .svg-inline--fa {
            font-size: pxToEm(20);
            line-height: pxToEm(20);
            color: var(--orgaimi-color-secondary-dimmed);
        }
    }

    /* Styles */
    &.-border {
        border-width: 1px 1px 1px 10px;
    }
    &.-error {
        border-color: var(--orgaimi-color-error-border);

        .icon {
            color: var(--orgaimi-color-error-border);
        }
    }
    &.-warning {
        border-color: var(--orgaimi-color-warning);

        .icon {
            color: var(--orgaimi-color-warning);
        }
    }
    &.-info {
        border-color: var(--orgaimi-color-info-border);
        .icon {
            color: var(--orgaimi-color-info-border);
        }
    }
    &.-success {
        border-color: var(--orgaimi-color-success-border);
        .icon {
            color: var(--orgaimi-color-success-border);
        }
    }

    /* Size */
    &.-small {
        padding: 10px;
        font-size: 13px;
        display: inline-block;

        .icon {
            font-size: 20px;
            line-height: 20px;
        }
    }

    &.-medium {
        padding: 10px;
        font-size: 14px;
        line-height: 32px;
        display: inline-block;
        .icon {
            font-size: 32px;
            line-height: 32px;
        }
    }
}
