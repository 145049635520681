@import "../../styles/base/_variables.scss";

.feedback {
    text-align: left;
    padding: pxToEm(20);

    .form-field {
        flex-grow: 1;
        background-color: var(--orgaimi-color-bg);

        textarea {
            min-height: 100px;
            background-color: var(--orgaimi-color-bg);
        }
    }

    h5 {
        color: var(--orgaimi-color-font) !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .multi-select {
        margin-bottom: pxToEm(20);

        .dropdown ul li {
            color: var(--orgaimi-color-gray70);
        }
    }
}
