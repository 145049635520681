.definition-list {
    margin: 0;
    padding: 0;
    list-style: none;

    font-size: pxToEm(16);
    line-height: pxToEm(16);
    font-weight: 300;
    color: var(--orgaimi-color-gray70);

    li {
        margin: 0 0 pxToEm(11);
        b {
            font-weight: 700;
        }

        &:last-child {
            margin: 0;
        }
    }
}
