.amount-with-change {
    text-align: center;

    .large-number h3 {
        margin-right: 0 !important;
    }

    h4 {
        margin-bottom: 0;
    }
}

.amount-change-group {
    margin-top: 8px;
    h5 {
        margin: 0 0 5px;
    }
}

.amount-change {
    display: flex;
    justify-content: center;

    .arrow {
        margin: 0 4px;
    }
}
