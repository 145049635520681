@import "../../styles/base/_variables.scss";

$bubble-small: 56px;
$bubble-medium: pxToEm(88);

.score-bubble {
    margin-bottom: 15px;
    width: $bubble-medium;
    height: $bubble-medium;
    min-width: $bubble-medium;
    line-height: $bubble-medium;
    position: relative;
    border-radius: 50%;
    background-color: var(--orgaimi-color-bg);

    color: var(--orgaimi-color-font);
    font-family: "interstate-compressed", sans-serif;
    font-weight: 800;
    font-size: calc($bubble-medium / 3);
    text-align: center;

    sup {
        position: relative;
        top: -0.75em;
        font-size: 0.5em;
        line-height: 0;
        vertical-align: baseline;
    }

    .arrow {
        margin-right: 2px;
        position: relative;
        top: -0.1em;
        font-size: pxToEm(26);
        color: var(--orgaimi-color-growth);
    }

    .circular-chart {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%) translateY(-50%);

        .circle {
            fill: none;
            stroke: var(--orgaimi-color-gray60);
            stroke-width: 2.8px;
            stroke-linecap: round;
            animation: progress 1s ease-out forwards;
        }
    }

    /* Variations */
    &.-healthy {
        &:not(.-arrow) {
            color: var(--orgaimi-color-healthy);
        }
        .arrow {
            color: var(--orgaimi-color-healthy);
        }

        .circular-chart {
            .circle {
                stroke: var(--orgaimi-color-healthy);
            }
        }
    }
    &.-unhealthy {
        &:not(.-arrow) {
            color: var(--orgaimi-color-unhealthy);
        }
        .arrow {
            color: var(--orgaimi-color-unhealthy);
        }
        .circular-chart {
            .circle {
                stroke: var(--orgaimi-color-unhealthy);
            }
        }
    }
    &.-potential {
        &:not(.-arrow) {
            color: var(--orgaimi-color-growth);
        }
        .arrow {
            color: var(--orgaimi-color-growth);
        }
        .circular-chart {
            .circle {
                stroke: var(--orgaimi-color-growth);
            }
        }
    }
    &.-svi {
        &:not(.-arrow) {
            color: var(--orgaimi-color-important);
        }
        .arrow {
            color: var(--orgaimi-color-important);
        }
        .circular-chart {
            .circle {
                stroke: var(--orgaimi-color-important);
            }
        }
    }
    &.-title {
        &:not(.-arrow) {
            color: var(--orgaimi-color-growth);
        }
    }
    &.-small {
        width: $bubble-small;
        height: $bubble-small;
        min-width: $bubble-small;
        line-height: $bubble-small;
        font-size: pxToEm(22);
    }
    &.-center {
        margin-left: auto;
        margin-right: auto;
    }

    &.-na {
        background-color: var(--orgaimi-color-bg2);
        font-weight: 500;
    }
}

.group {
    .score-bubble {
        margin: 0 15px 25px;
    }
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}
