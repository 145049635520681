@import "../../../styles/base/_variables.scss";
@import "../../../styles/base/_mixins.scss";

.multi-range-slider {
    padding: pxToEm(20);
    @include no-select;

    .slider {
        width: 200px;
        position: relative;
    }

    .slider-range {
        position: absolute;
    }

    .slider-track,
    .slider-range {
        border-radius: pxToEm(5);
        height: pxToEm(10);
    }

    .slider-track {
        position: relative;
        background-color: var(--orgaimi-color-gray50);
        width: 100%;
        z-index: 1;
    }

    .slider-range {
        top: 0;
        background-color: var(--orgaimi-color-accent1);
        z-index: 2;
    }

    .slider-value {
        margin-top: pxToEm(10);
        display: flex;
        justify-content: center;

        font-family: "interstate-compressed", sans-serif;
        font-size: 2.2222222222rem;
        font-weight: 500;
    }

    /* Varients */
    &.-center {
        .slider {
            margin: 0 auto;
        }
    }
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 200px;
    outline: none;
}

.thumb--zindex-3 {
    z-index: 3;
}

.thumb--zindex-4 {
    z-index: 4;
}

.thumb--zindex-5 {
    z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: pxToEm(20);
    width: pxToEm(20);
    margin-top: pxToEm(10);
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: pxToEm(20);
    width: pxToEm(20);
    margin-top: pxToEm(10);
    pointer-events: all;
    position: relative;
}

.multi-range-slider-dropdown {
    display: block;
    background-color: var(--orgaimi-color-bg2);
    border: 1px solid var(--orgaimi-color-bg);
    font-family: "interstate-condensed";
    position: relative;

    &.-error {
        border-color: var(--orgaimi-color-error-border);
    }

    .label {
        min-width: 200px;
        height: 100%;
        padding: pxToEm(18) pxToEm(22) pxToEm(15);
        padding-right: 2.2rem;
        cursor: pointer;
        border-bottom: 1px solid var(--orgaimi-color-bg);
        text-align: left;
        position: relative;
        color: var(--orgaimi-color-font);
        text-transform: uppercase;

        label,
        .label-span {
            margin-right: 3px;
            color: var(--orgaimi-color-secondary-dimmed);
            font-weight: bold;
            cursor: pointer;
        }
        .arrow {
            position: absolute;
            top: 50%;
            right: 1.111rem;
            transform: translateY(-50%);

            .svg-inline--fa {
                color: var(--orgaimi-color-secondary-dimmed);
            }
        }
    }

    .dropdown {
        min-width: 220px;
        width: 100%;
        overflow: hidden;
        list-style: none;
        display: none;
        text-align: left;
        position: absolute;
        background-color: var(--orgaimi-color-bg2);
        box-shadow: 0px 2px 30px #00000066;
        z-index: 10;

        &.-open {
            display: block;
        }

        .background-line {
            margin: 0 1.111rem;
            span {
                margin-left: 13px;
                padding: 0 5px;
            }
        }

        .multi-range-slider {
            label {
                display: none;
            }
            .slider {
                width: 100%;
            }
            .thumb {
                width: 100%;
            }
        }
        .button-group {
            .button {
                flex-grow: 1;
            }
        }
    }

    /* Variants */
    &.-small {
        .label {
            font-size: pxToEm(16);
            padding: pxToEm(5);
        }
    }

    &:focus,
    &:active {
        border-color: var(--orgaimi-color-gray50);
        outline: none;
    }
}
