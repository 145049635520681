@import "../../../../styles/base/_variables.scss";

.dictionary-edit {
    & > div {
        display: grid;
        grid-template-columns: auto 1fr;
        label {
            margin-bottom: 3px;
            margin-right: 1px;
            padding: pxToEm(10);
            text-align: right;
            align-self: center;
            font-family: "interstate-condensed", sans-serif;
            color: var(--orgaimi-color-gray60);
            background-color: var(--orgaimi-color-bg2);
        }

        input {
            margin-bottom: 3px;
            padding: pxToEm(10);
            background-color: var(--orgaimi-color-bg2-a50);
            border: none;
            color: var(--orgaimi-color-font);
            font-family: "interstate-condensed", sans-serif;
            min-width: 100px;
            flex-grow: 1;
        }
    }
    .form-field {
        margin-bottom: 10px;
    }

    article {
        width: 400px;
        position: fixed;
        right: 10px;
        background: #051d27;
        padding: 30px;
        .change-log-entry {
            margin: 0.5rem 0;
        }
    }
}

#dictionary-changes {
    position: fixed;
    width: pxToEm(440);
    top: 196px;
    right: pxToEm(30);
    background-color: var(--orgaimi-color-bg2-a80);

    .change-wrapper {
        max-height: 0;
        overflow: hidden;
        overflow-y: scroll;
        transition: max-height 400ms ease-in-out;
    }

    .change-list {
        padding: pxToEm(20) pxToEm(30);
        .change-item {
            margin-bottom: 10px;
        }
    }

    .flex-header {
        background-color: var(--orgaimi-color-bg2);
        margin-bottom: 0;
        padding: pxToEm(5) pxToEm(30);
        border-bottom: 1px solid var(--orgaimi-color-bg);
        h3 {
            margin: 0 0 0 pxToEm(14);
            flex-grow: 1;
        }

        .svg-inline--fa {
            font-size: pxToEm(26);
            transform: rotate(-90deg);
            transition: transform 300ms ease-in-out;
        }
    }

    &.-open {
        .change-wrapper {
            max-height: 50vh;
        }
        .flex-header {
            .svg-inline--fa {
                transform: rotate(0deg);
            }
        }
    }
}
