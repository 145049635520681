@import "../../../../styles/base/_variables.scss";

.collapsing-pills {
    .extra-container {
        position: relative;
        text-align: center;
        .extra {
            overflow: hidden;
            opacity: 0;
            max-height: 0;
            z-index: 0;
            transition: opacity ease-in-out 0.25s, max-height ease-in-out 0.25s;

            &.-show {
                display: block;
                opacity: 1;
                max-height: 400px;
            }
        }

        .tooltip {
            position: absolute;
            padding: 1em;
            overflow: hidden;
            opacity: 0;
            max-height: 0;
            width: 100%;
            z-index: 0;
            transition: opacity ease-in-out 200ms, max-height ease-in-out 400ms;

            /* Positioning */
            display: flex;
            justify-content: center;

            &.-hover {
                opacity: 1;
                max-height: 400px;

                top: 100%;
                z-index: 1;

                background: var(--orgaimi-color-bg);
                box-shadow: var(--orgaimi-box-shadow);
                border: 1px solid var(--orgaimi-color-bg2);
                border-radius: 8px;
            }
        }

        .show-more {
            margin-bottom: 10px;
            padding: 2px 6px;
            font-size: 0.833rem;
            border: none;
            border-radius: 8px;
            display: inline-block;
            background: var(--orgaimi-color-bg);
            color: var(--orgaimi-color-font);
            width: 30px;

            font-family: "interstate-condensed", sans-serif;
            cursor: pointer;
            outline: none;
            &.-show {
                padding: 5px 2px 0 2px;
            }
        }
    }

    /* Variations */
    &.-left {
        .pill-list {
            align-items: flex-start;
        }
    }
}
