@import "../../styles/base/_variables.scss";

.login-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    h1,
    h3 {
        color: var(--orgaimi-color-font);
    }

    aside {
        position: relative;
        z-index: 3;
        padding: 30px;
        box-shadow: 0px 2px 30px rgba(255, 255, 255, 0.1);
        background-color: var(--orgaimi-color-header-bg);
        display: flex;
        flex-direction: column;

        .logo {
            margin: 0 auto 40px;
            width: 60%;
        }
        .login-button {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .login-form {
            label {
                margin: 20px 0 8px;
                font-size: 0.889rem;
                display: block;
            }
        }

        .background-line {
            margin: 15px 0;
        }

        .aside-footer {
            align-self: end;
            font-size: 0.5em;
            opacity: 0.5;
        }
    }
    main {
        padding: 30px;
        flex-grow: 1;
        background: url("/images/technology-bg.png") center no-repeat;
        background-size: cover;
        position: relative;

        &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--orgaimi-color-header-bg) !important;
            opacity: 0.8 !important;
            content: " ";
        }

        .container {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;

            h1 {
                margin: 30px 0 0;
                text-transform: none;
                font-weight: normal;
            }
        }
    }
}

@media (min-width: $screen-sm-max) {
    .login-page {
        flex-direction: row;

        aside {
            width: 28%;
            max-width: 460px;
            height: 100vh;
        }
    }
}
