@import "../../styles/base/_variables.scss";

.info-table {
    margin: pxToEm(18) 0 pxToEm(40);

    .info-cell {
        padding: pxToEm(30);
        background-color: var(--orgaimi-color-bg2);

        h4 {
            margin: 0;
            color: var(--orgaimi-color-font);
            font-family: "interstate", sans-serif;
            font-weight: normal;
            a {
                color: var(--orgaimi-color-font);
                text-decoration: none;

                &:hover {
                    color: var(--orgaimi-color-secondary);
                }
            }
        }
        h5 {
            margin: 0 0 pxToEm(5);
            text-transform: uppercase;
            color: var(--orgaimi-color-gray70);
            font-family: "interstate-condensed", sans-serif;
            font-weight: normal;
        }
        h6 {
            margin-top: 4px;
            color: var(--orgaimi-color-gray40);
            font-size: 1rem;
        }

        .focus-info {
            display: flex;
            flex-direction: column;
            height: 100%;
            text-align: center;
            h3 {
                color: var(--orgaimi-color-accent1);
            }

            h5 {
                margin-bottom: pxToEm(15);
            }
        }

        .team-members {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;

            .profile-icon {
                margin: 0 auto 8px;
            }
            a {
                color: var(--orgaimi-color-gray70);
                text-decoration: none;
            }
        }
        /*.comments-wrapper {
            flex-direction: column-reverse;
        }*/
        .large-number {
            display: flex;
            flex-direction: column-reverse;
            height: 100%;
            text-align: center;

            h3 {
                font-size: pxToEm(44);
                font-family: "Interstate-compressed", sans-serif;
            }

            div:first-child {
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .probability-bubble {
            display: flex;
            flex-direction: column-reverse;
            height: 100%;

            .probability-circle-wrapper {
                flex-grow: 1;
                display: flex;
                align-items: center;
            }
        }
    }
    .link-info-content {
        display: flex;

        h4 {
            flex-grow: 1;
        }
        & > * {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
        a {
            .svg-inline--fa {
                font-size: 20px;
                color: var(--orgaimi-color-font);
                transition: color 400ms;
                &:hover {
                    color: var(--orgaimi-color-link);
                }
            }
        }

        &.-faded {
            a {
                color: var(--orgaimi-color-gray70);
            }
            .svg-inline--fa {
                color: var(--orgaimi-color-gray70);
            }
        }
    }
}

@media (max-width: $screen-sm-max) {
    .info-table {
        .info-cell {
            border-bottom: 1px solid var(--orgaimi-color-bg);
        }
    }
}

@media (min-width: $screen-sm-max) {
    .info-table {
        display: grid;
        grid-gap: 1px;
    }
}
