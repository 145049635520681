@import "../../../styles/base/variables";

.temperature-bar-wrapper {
    display: flex;
    align-items: center;

    .large-number {
        min-width: 95px;
        h3 {
            font-family: "interstate-compressed", sans-serif;
            font-size: pxToEm(44);
            line-height: pxToEm(44);
        }
    }
}
.temperature-bar {
    min-width: pxToEm(195);
    margin-right: 10px;
    .bar {
        margin-bottom: pxToEm(10);
        width: 100%;
        height: pxToEm(4);
        background-color: var(--orgaimi-color-accent1);
        position: relative;

        .line {
            position: absolute;
            top: 0;
            left: 0;
            height: pxToEm(4);
            background-color: var(--orgaimi-color-secondary);

            transition: width 400ms ease-in-out;
        }

        .dot {
            width: pxToEm(12);
            height: pxToEm(12);
            border-radius: 50%;
            border: 2px solid var(--orgaimi-color-bg2);
            background-color: var(--orgaimi-color-accent1);
            position: absolute;
            top: 50%;
            left: -2px;
            z-index: 2;
            transform: translateY(-50%);

            transition: left 400ms ease-in-out;
        }
    }

    .label {
        font-family: "interstate-condensed", sans-serif;
        font-size: pxToEm(16);
        line-height: pxToEm(16);
        color: var(--orgaimi-color-gray60);
        text-transform: uppercase;
    }

    /* Variation */
    &.-healthy {
        .bar {
            background-color: var(--orgaimi-color-healthy);
        }

        .dot {
            background-color: var(--orgaimi-color-healthy);
        }
    }
    /* Variation */
    &.-unhealthy {
        .bar {
            background-color: var(--orgaimi-color-unhealthy);
        }

        .dot {
            background-color: var(--orgaimi-color-unhealthy);
        }
    }
    /* Importance */
    &.-importance {
        .bar {
            background-color: var(--orgaimi-color-important);
        }

        .dot {
            background-color: var(--orgaimi-color-important);
        }
    }
}
