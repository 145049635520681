@import "../../../../styles/base/_variables.scss";

.name-container {
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    align-items: center;

    p {
        margin: 0;
        font-size: pxToEm(14);
        color: var(--orgaimi-color-gray70);
    }
    .tooltip {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        opacity: 0;
        width: auto;
        max-width: 100%;
        transition: opacity ease-in-out 0.5s, max-height ease-in-out 0.5s, width ease-in-out 0.2s;
        transform: translateX(-50%) translateY(-50%);

        /* Styles */
        background: var(--orgaimi-color-bg);
        box-shadow: var(--orgaimi-box-shadow);
        border: 1px solid var(--orgaimi-color-bg2);
        border-radius: 8px;
        padding: 1em;
    }
    &.-hover {
        .tooltip {
            opacity: 1;
        }
    }
    &.-anchor {
        cursor: pointer;
    }
}

.initials-list {
    .name-container {
        margin-bottom: 15px;
    }
}

.collapsing-pills.-initials {
    .extra-container {
        .tooltip {
            width: auto;
            .initials-list {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                .name-container {
                    margin-right: 15px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
