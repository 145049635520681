@import "../../styles/base/_variables.scss";

.notification-panel {
    position: fixed;
    top: pxToEm(66);
    right: 0px;
    height: calc(100vh - pxToEm(66));
    z-index: 998;
    background-color: var(--orgaimi-color-bg);
    box-shadow: var(--orgaimi-box-shadow);
    text-align: left;
    min-width: 400px;

    transform: translateX(100%);
    transition: transform 400ms ease-in-out;

    header {
        max-height: 115px;
        padding: pxToEm(15);
        h2 {
            margin-bottom: 0;
        }
    }

    .flex-header {
        align-items: center;
        h2 {
            flex-grow: 1;
        }
        h4 {
            margin: 0 10px 0 0;
        }
    }

    .settings {
        padding: pxToEm(20);
    }

    .message-list {
        flex-grow: 1;
        text-align: left;
        overflow-y: scroll;

        max-height: calc(100% - 115px);
        width: 100%;

        &.-no-messages {
            display: flex;
            align-items: center;

            h5 {
                width: 100%;

                font-size: pxToEm(24);
                text-align: center;
                text-transform: none;
            }
        }

        .message {
            padding-left: pxToEm(20);
            padding-right: pxToEm(20);
            margin-bottom: 1px;
            position: relative;
            display: flex;
            align-items: center;

            background-color: var(--orgaimi-color-bg2);

            .profile-icon {
                margin: 0 pxToEm(15) 0 pxToEm(10);
                min-width: pxToEm(64);
                width: pxToEm(64);
                height: pxToEm(64);
                line-height: pxToEm(64);
                font-size: pxToEm(30);
            }

            .content {
                padding: pxToEm(20) 0;
                flex-grow: 1;

                p {
                    margin: pxToEm(5) 0 pxToEm(5);
                    font-weight: normal;
                }

                .name-date {
                    display: flex;
                    justify-content: space-between;
                }

                .time {
                    color: var(--orgaimi-color-gray70);
                }
            }

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
            }

            .state {
                width: pxToEm(20);
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--orgaimi-color-bg2);
                font-size: pxToEm(15);

                svg {
                    opacity: 0;
                    transition: opacity 400ms ease-in-out;
                    transform: rotate(90deg);
                }
            }

            &.-unread {
                cursor: pointer;
                .state {
                    opacity: 1;
                    background-color: var(--orgaimi-color-accent1);
                }
            }
            &.-archive {
                .state {
                    opacity: 1;
                    cursor: pointer;
                }
                &:hover .state {
                    &:hover {
                        background-color: var(--orgaimi-color-accent2);
                    }
                }
            }

            &:hover {
                .state {
                    svg {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .button-list {
        margin: 0;
        display: flex;
        justify-content: space-evenly;
        button {
            display: flex;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            padding: pxToEm(18);
            font-family: "interstate-condensed";
            font-size: pxToEm(20);
            font-weight: 700;
            background-color: var(--orgaimi-color-bg);

            &.-active {
                cursor: default;
            }
        }
    }

    // Variation
    &.-active {
        transform: translateX(0%);
    }
}

@media (min-width: $screen-md-min) {
    .notification-panel {
        width: pxToEm(728);
    }
}
