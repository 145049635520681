@import "../../styles/base/_variables.scss";

.header {
    background-color: var(--orgaimi-color-header-bg);
    font-family: "interstate";
    font-weight: 700;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 2px 6px #0000004d;

    .container {
        margin: 0 auto;
        padding: 0;
        display: flex;

        align-items: center;
        height: pxToEm(66);
        position: relative;
    }

    .hamburger-react {
        margin-top: -4px;
        opacity: 0.5;
    }

    .logo-wrapper {
        display: flex;
        align-items: center;
    }
    .logo {
        padding: 0 30px 0 0;
        position: relative;

        img {
            height: 20px;
        }

        .build-number {
            font-size: pxToEm(9);
            width: 100%;
            position: absolute;
            top: 20px;
            height: 1rem;
            color: transparent;
            white-space: nowrap;
        }
    }

    .main-dropdown {
        display: block;
        min-width: 180px;
        height: calc(100vh - pxToEm(66));
        position: absolute;
        top: pxToEm(66);
        left: 0;
        z-index: 999;
        box-shadow: 0px 2px 6px #0000004d;
        background-color: var(--orgaimi-color-header-bg);
        transform: translateX(-100%);

        overflow: hidden;
        transition: transform 400ms ease-in-out;

        &.-active {
            transform: translateX(0%);
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                a {
                    padding: 10px 20px;
                    display: block;
                    text-decoration: none;
                    color: var(--orgaimi-color-header-font);

                    &:hover {
                        background-color: var(--orgaimi-color-bg);
                    }
                }
            }
        }
    }

    .main-nav {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;

        list-style-type: none;

        & > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            position: relative;

            li {
                height: 100%;
                white-space: nowrap;

                & > a {
                    margin: 0;
                    padding: 0 pxToEm(15);
                    display: block;
                    color: var(--orgaimi-color-header-font-a60);
                    font-size: pxToEm(20);
                    line-height: pxToEm(66);
                    font-weight: 700;
                    text-decoration: none;
                    text-transform: uppercase;

                    transition: color ease 400ms;
                }
                &:hover a {
                    color: var(--orgaimi-color-header-font);
                }
                &.-selected a {
                    color: var(--orgaimi-color-header-font);
                }
            }
        }

        .underline {
            width: 100px;
            height: 1px;
            background-color: var(--orgaimi-color-accent1);

            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .utility-nav {
        height: 100%;
        display: flex;
        align-items: flex-end;
        text-align: center;

        & > ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            height: 100%;
            display: flex;
            align-items: flex-start;
            & > li {
                margin: pxToEm(5) pxToEm(5) 0 0;
                position: relative;

                & > a {
                    width: pxToEm(56);
                    height: pxToEm(56);
                    line-height: pxToEm(56);
                    display: block;
                    color: var(--orgaimi-color-accent2);
                    text-decoration: none;
                    background-color: var(--orgaimi-color-bg);
                    position: relative;
                    z-index: 999;

                    transition: background-color ease 400ms, height ease 400ms;

                    img {
                        max-width: pxToEm(27);
                        transition: filter ease 400ms;
                        vertical-align: middle;
                        filter: invert(42%) sepia(7%) saturate(4779%) hue-rotate(147deg) brightness(89%) contrast(87%); // Light Blue
                    }
                }
                &:hover:not(.-selected) a {
                    background-color: var(--orgaimi-color-bg);
                }
                &.-selected {
                    & > a {
                        height: pxToEm(61);
                    }

                    img {
                        filter: invert(98%) sepia(11%) saturate(94%) hue-rotate(224deg) brightness(112%) contrast(91%); // White
                    }
                }
            }
        }

        .pill-count {
            position: absolute;
            top: pxToEm(5);
            right: pxToEm(5);

            display: flex;
            justify-content: center;
            align-items: center;

            min-width: pxToEm(20);
            min-height: pxToEm(20);

            font-family: "interstate-condensed", sans-serif;
            font-weight: 800;
            font-size: pxToEm(10);
            line-height: pxToEm(20);
            color: var(--orgaimi-color-header-bg);
            background: linear-gradient(to bottom, var(--orgaimi-color-accent1) 0%, var(--orgaimi-color-accent1) 100%);
            border-radius: 50%;
        }
    }
}

@media (max-width: 950px) {
    .header {
        .main-nav {
            display: none;
        }
        .utility-nav {
            flex-grow: 1;
            justify-content: flex-end;
        }
    }
}

@media (min-width: $screen-md-min) {
    .header {
        .logo-wrapper {
            flex-grow: 1;
        }
        .main-nav {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
