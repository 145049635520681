html {
    font-size: 14px;
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    line-height: 1.2;
    color: var(--orgaimi-color-font);
    font-family: "Interstate", Arial, sans-serif;
    background-color: var(--orgaimi-color-bg);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: var(--orgaimi-color-link);
    transition: color 400ms;
    &:hover {
        color: var(--orgaimi-color-secondary-dimmed);
    }
}
img {
    @include img-responsive;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 1rem;
    font-family: "interstate-condensed";
    font-weight: 500;
    line-height: 1em;
    color: var(--orgaimi-color-font);

    em {
        font-style: normal;
        color: var(--orgaimi-color-accent1);
    }

    sup {
        position: relative;
        top: -0.4em;
        font-size: 0.6em;
        line-height: 0;
        vertical-align: baseline;
    }

    .memo {
        font-size: 80%;
        font-weight: normal;
    }
    .arrow {
        font-size: 0.75em;
        position: relative;
        top: -0.13em;
    }

    transition: font-size 300ms;
    .svg-inline--fa {
        font-size: inherit;
    }
    &.-bar {
        margin: 0;
        padding: 4px;
        background-color: var(--orgaimi-color-primary);
        text-align: center;
        color: var(--orgaimi-color-font);
    }
}

h1 {
    font-size: 2.778rem;
    text-transform: uppercase;
}

h2 {
    font-size: 2.333rem;
}

h3 {
    font-size: 1.667rem;
}

h4 {
    font-size: pxToEm(20);
    color: var(--orgaimi-color-gray40);
}

h5 {
    font-family: "interstate";
    font-weight: 700;
    font-size: 0.889rem;
    text-transform: uppercase;
}
h6 {
    font-size: 0.778rem;
}

p {
    margin: 0 0 20px;
}

hr {
    height: 1px;
    background-color: var(--orgaimi-color-bg);
    border: none;

    &.-dark {
        background-color: var(--orgaimi-color-bg2);
    }
}

ul.no-bullet {
    list-style: none;
    margin: 10px 0 0;
    padding: 0;

    li {
        padding: 2px 0;
    }
}

.arrow {
    .svg-inline--fa {
        color: var(--orgaimi-color-accent1);
    }
    &.-unhealthy {
        .svg-inline--fa {
            color: var(--orgaimi-color-unhealthy);
        }
    }
    &.-healthy {
        .svg-inline--fa {
            color: var(--orgaimi-color-healthy);
        }
    }
}

/* Structural */
.container {
    margin: 0 auto;
    padding: 10px 20px;
    //max-width: $max-width;
    @include clearfix;
    box-sizing: border-box;
    &.-loading {
        min-height: 100vh;
        position: relative;
    }
}

/* Global Variations */
.hide {
    display: none;
}
.hide-svg {
    width: 0;
    height: 0;
    position: absolute;
}
.disable {
    position: relative;
    &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: var(--orgaimi-color-font);
        opacity: 0.5;
    }
}
.-shadow {
    box-shadow: var(--orgaimi-box-shadow);
    position: relative;
    z-index: 2;
}

fieldset {
    margin-bottom: 20px;
}
.group {
    display: flex;

    &.-center {
        justify-content: center;
    }
}

.background-line {
    border-top: 1px solid var(--orgaimi-color-font);
    line-height: 1;

    span {
        transform: translateY(-50%);
        background-color: var(--orgaimi-color-bg);
        display: inline-block;
        padding: 0 10px 0 0;
        position: relative;
        top: -1px;
    }

    &.-darker {
        border-color: var(--orgaimi-color-bg);

        span {
            background-color: var(--orgaimi-color-bg2);
        }
    }

    &.-center {
        text-align: center;
        span {
            padding: 0 8px;
        }
    }

    &.-gray {
        border-color: var(--orgaimi-color-gray50);

        span {
            color: var(--orgaimi-color-gray50);
        }
    }

    &.-none {
        span {
            background: none;
        }
    }
}

.no-content {
    text-align: center;
}

// Overlay
.overlay {
    background-color: rgba(var(--orgaimi-color-bg2), 0.8);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow-y: auto;
    padding: 40px 20px 30px;
    display: flex;
    align-items: flex-start;
    z-index: 100;
    animation: fade-in 700ms 1 linear;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.panel-contents {
    margin: 0 auto;
    width: auto;
    height: 100%;
    position: fixed;
    top: pxToEm(66);
    right: 0;
    transform: translate3d(110%, 0, 0);
    z-index: 101;
    transition: transform 450ms ease, -webkit-transform 450ms ease;
    overflow: hidden;

    &.-active {
        transform: translate3d(0, 0, 0);
    }
}

.chart-container {
    margin-bottom: pxToEm(30);
    width: 100%;
    height: 400px;
    background-color: var(--orgaimi-color-bg2);
}

.external-legend {
    &.-center {
        width: 100%;
        display: flex;
        justify-content: center;
        bottom: 20px;

        & > div {
            margin: 0 20px 0 0;
            &:last-child {
                margin: 0;
            }
        }
    }
    & > div {
        &.-link {
            cursor: pointer;
        }
    }
}

.external-label {
    margin-bottom: pxToEm(8);
    display: flex;
}

.color-swatch {
    margin-right: 5px;
    width: pxToEm(20);
    height: pxToEm(20);
    display: inline-block;
}

@keyframes fade-in {
    0% {
        animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Scroll */
::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(var(--orgaimi-color-bg2), 0.3);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: var(--orgaimi-color-gray30);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
}
::-webkit-scrollbar-corner {
    background-color: var(--orgaimi-color-bg);
}

/* honeypot*/
#surname {
    display: none;
}

/* Quotes */
blockquote {
    margin: 5px 0 15px;
    color: #555555;
    padding: 20px 20px 10px 50px;
    border-left: 8px solid var(--orgaimi-color-primary);
    line-height: 1.6;
    position: relative;
    background: #ededed;

    p {
        margin: 0;
        font-size: 1.3em;
        font-style: italic;
    }
}
blockquote::before {
    content: "\201C";
    color: var(--orgaimi-color-secondary);
    font-size: 6em;
    position: absolute;
    left: 10px;
    top: -25px;
}
blockquote::after {
    content: "";
}
blockquote cite {
    display: block;
    color: #333333;
    font-style: normal;
    margin-top: 1em;
}

ul.additive {
    list-style: none;
    padding-left: 10px;

    li {
        margin-bottom: 3px;
    }

    li:before {
        content: "\2013";
        margin-right: 5px;
    }
    li.-plus:before {
        content: "+";
        margin-right: 3px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg {
    &.-dark {
        background-color: var(--orgaimi-color-bg2);
    }
}

.no-data {
    margin: pxToEm(40) 0;
}

// @2x
@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min--moz-device-pixel-ratio: 2) {
    html {
        font-size: 17px;
    }
}
// @3x
@media screen and (-webkit-min-device-pixel-ratio: 3), screen and (min--moz-device-pixel-ratio: 3) {
    html {
        font-size: 18px;
    }
}

/* Handle 125% and 150% Windows 10 Font Scaling on 96dpi monitors */
@media (min-resolution: 120dpi) {
    html {
        font-size: 12px;
    }
}
