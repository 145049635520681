.main {
    margin-top: pxToEm(30);
}
.main-wrapper {
    position: relative;
}

.page-container {
    margin-top: $header-height;
    width: 100vw;
    height: calc(100vh - $header-height);
    overflow: auto;

    &.-with-stats {
        margin-top: 0;
        height: calc(100vh - $header-height);
    }
}

.page-content {
    padding: 30px;
}

#sidebar .caret {
    position: absolute;
    top: pxToEm(27);
    right: -25px;
    transition: right ease 0.2s;
}

.flex-header {
    margin-bottom: pxToEm(18);
    h3 {
        margin-bottom: 0.7rem;
    }
}

@media (max-width: $screen-md-max) {
    .main {
        & > main {
            margin-bottom: 30px;
            position: relative;
        }
    }
    .page .main {
        padding-left: pxToEm(40);
        padding-right: pxToEm(40);
    }
    #sidebar .caret {
        display: none;
    }
}

@media (min-width: $screen-md-min) {
    .main-wrapper {
        h1:first-child {
            margin-top: pxToEm(16);
        }
    }
    .flex-header {
        margin-top: pxToEm(6);
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > h1 {
            margin-top: 0;
        }
        .toggle-switch {
            white-space: nowrap;
        }
    }
    .page {
        position: relative;

        /* & > .breadcrumb {
            position: absolute;
            top: 0;
            left: 0;
        }*/
    }
}

@media (min-width: $screen-lg-min) {
    html {
        font-size: 16px;
    }
    .main {
        height: 100%;
        transition: padding 300ms ease-in-out;

        & > main {
            //width: calc(100% - pxToEm(560));
            height: 100%;
            margin-left: pxToEm(30);
            margin-right: pxToEm(30);
            position: relative;
            z-index: 2;
            transition: transform 400ms ease-in-out, width 400ms ease-in-out;
            //transform: translateX(pxToEm(500));
        }
    }

    .-stowed {
        main {
            width: calc(100% - pxToEm(60));
            transform: translateX(0px);
        }
        aside {
            transform: translateX(-100%);
        }
    }
    .page-content {
        display: flex;

        & > .content {
            margin-left: 30px;
            flex-grow: 1;
        }

        &.-shadow {
            box-shadow: 0px 2px 30px #00000066;
        }
    }

    #sidebar {
        width: pxToEm(500);

        /* Loading base animations */
        .viewing-for,
        .manager,
        .filter-item,
        .info-list {
            opacity: 1;
            transition: opacity 400ms ease-in-out;
        }

        &.-loading {
            .viewing-for,
            .manager,
            .filter-item,
            .info-list {
                opacity: 0;
            }
            .manager-box,
            .filter-box {
                background: var(--orgaimi-color-bg-dimmed);
                background-size: 400% 400%;
                animation: gradient 3s ease infinite;
            }
        }
    }

    aside {
        #sidebar {
            position: sticky;
            top: $filter-closed-height;
            .sidebar-content {
                box-shadow: var(--orgaimi-box-shadow);
                min-height: 548px;
            }
        }
    }
}
