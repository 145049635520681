.global-error {
    display: block;
    position: fixed;
    top: 50px;
    height: 0;
    padding: 0;
    margin: 0;
    transition: height 0.5s ease-in-out, padding 0.5s ease-in-out, margin 0.5s ease-in-out;
    z-index: 1000;
    width: 100%;
    background-color: var(--orgaimi-color-error-banner);

    text-align: center;
    font-weight: 700;

    &.-visible {
        height: 3.5em;
        padding: 1em;
    }
}
