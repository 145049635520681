.react-datepicker-wrapper {
    width: 100%;

    button {
        padding: 0;
        &:after {
            padding: 0;
            background: none;
            color: var(--orgaimi-color-accent2);
            font-size: 20px;
            height: 20px;
            width: 20px;
        }
    }
}
