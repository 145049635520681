.w-md-editor,
.w-md-editor-toolbar,
.wmde-markdown {
    background-color: var(--orgaimi-color-bg2) !important;
    border-color: var(--orgaimi-color-bg) !important;
}

.w-md-editor {
    box-shadow: 0 0 0 1px var(--orgaimi-color-bg), 0 0 0 var(--orgaimi-color-bg), 0 1px 1px var(--orgaimi-color-bg) !important;
}
.w-md-editor-preview {
    box-shadow: inset 1px 0 0 0 var(--orgaimi-color-bg) !important;
}
