@import "../../styles/base/_variables.scss";
@import "../../styles/base/_mixins.scss";

.client-list {
    /*bottom: 0;
    display: flex;
    flex-direction: column;*/

    .table {
        //flex-grow: 1;
    }
    .chart-column-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .chart-line-simple {
            margin-right: pxToEm(20);
            height: 100px;
        }
    }
    .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80vh;
    }
}

#client {
    .page-content {
        padding: pxToEm(22) 0 30px;
        display: block;
    }

    .client-nav {
        margin: 0 pxToEm(30) pxToEm(18);
        display: flex;
        justify-content: space-between;

        .mobile-client-nav {
            display: none;
        }

        .button-list {
            margin-bottom: 0;

            a {
                font-size: pxToEm(20);
                line-height: pxToEm(20);
                font-weight: 700;
                color: var(--orgaimi-color-font-dimmed);

                &.-active {
                    color: var(--orgaimi-color-font);
                }
            }
        }
        .button {
            justify-self: flex-end;
            font-size: pxToEm(18);
            line-height: pxToEm(24);
            color: var(--orgaimi-color-secondary-dimmed);

            .svg-inline--fa {
                font-size: pxToEm(24);
                color: var(--orgaimi-color-font-dimmed);
            }
        }
    }

    .outlet {
        padding: pxToEm(40) pxToEm(50);
    }
}

#recommendations {
    & > p {
        margin-bottom: pxToEm(35);
    }
}

/*@media (min-width: $screen-md-min) {
    #segments,
    #client-list {
        .main-wrapper {
            margin-right: pxToEm(30);
        }
    }
}*/

.subclient-top5 {
    display: flex;
}

.chart-scatter {
    margin-bottom: pxToEm(40);
    display: flex;
    align-items: stretch;
    .external-legend {
        margin-left: 2px;
        padding: pxToEm(30);
        min-width: 250px;
        background-color: var(--orgaimi-color-bg2);
    }

    .chart-container {
        margin: 0;
    }
}

.score-theme-charts {
    display: grid;
    grid-template-columns: 60% 40%;
    justify-content: stretch;
    grid-gap: 2px;

    & > div {
        padding: pxToEm(40);
        background-color: var(--orgaimi-color-bg2-a70);
    }
    .chart-theme-performance {
        width: 100%;
        height: 100%;
        max-height: 400px;
        justify-content: center;
    }

    .chart {
        margin-top: 30px;
    }
}

@media (max-width: $screen-md-min) {
    #client {
        .client-nav {
            .mobile-client-nav {
                display: block;
            }
            .button-list {
                display: none;
            }
        }
    }
    .theme-summary {
        grid-template-columns: 1fr;
    }
    .score-theme-charts {
        grid-template-columns: 1fr;
    }
}
